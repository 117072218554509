import { html } from "lit-html";
import { useMemo } from "GlobalShared/haunted/CustomHooks";
import { useVatServices } from "./order-vat-services";
import { useCountrySelector } from "./order-country-selector";
import { useIossServices } from "./order-ioss-services";
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { useGlobalServices } from "./order-global-services";
import { isEuCountry, SERVICE_COUNTRIES } from "GlobalShared/helpers/countryHelper";
import { useOssServices } from "./order-oss-services";
export const useOrderServices = (props) => {
  const areVatServicesEnabled = () => {
    var _a, _b;
    return props.servicesFlowType === "Signup" || props.servicesFlowType === "AddServices" && ((_b = (_a = countrySelectorHook.vatCountrySelection) == null ? void 0 : _a.filter((vcs) => {
      var _a2;
      return (_a2 = props.priceConfig) == null ? void 0 : _a2.perCountry[vcs.code];
    })) == null ? void 0 : _b.length) > 0;
  };
  const isAnyIossServiceAvailable = () => {
    var _a, _b, _c, _d, _e;
    return ((_b = (_a = props.priceConfig) == null ? void 0 : _a.ioss) == null ? void 0 : _b.deregistration) !== void 0 || ((_e = (_d = (_c = props.priceConfig) == null ? void 0 : _c.ioss) == null ? void 0 : _d.tiers) == null ? void 0 : _e.length) > 0;
  };
  const areIossServicesEnabled = () => {
    var _a;
    return !isEuCountry(props.euCountries, props.countryOfEstablishment) && (props.servicesFlowType === "Signup" || props.servicesFlowType === "AddServices" && ((_a = iossServicesHook.iossService) == null ? void 0 : _a.origTier) !== "Tier0") && isAnyIossServiceAvailable();
  };
  const isEUCountryAvailableForOss = (country) => [SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.France, SERVICE_COUNTRIES.Italy, SERVICE_COUNTRIES.Poland, SERVICE_COUNTRIES.Netherlands].includes(country);
  const areOssServicesEnabled = () => !isEuCountry(props.euCountries, props.countryOfEstablishment) || isEUCountryAvailableForOss(props.countryOfEstablishment) && (props.isVatEUValid || props.servicesFlowType === "AddServices");
  const countrySelectorHook = useCountrySelector({
    devMode: props.devMode,
    isAdmin: props.isAdmin,
    isSignup: props.isSignup,
    worldCountries: props.worldCountries,
    serviceCountries: props.serviceCountries,
    priceConfig: props.priceConfig,
    currentCompanyServices: props.currentCompanyServices
  });
  const vatServicesHook = useVatServices({
    devMode: props.devMode,
    isAdmin: props.isAdmin,
    isSignup: props.isSignup,
    vatCountrySelection: countrySelectorHook.vatCountrySelection,
    worldCountries: props.worldCountries,
    priceConfig: props.priceConfig,
    currentCompanyServices: props.currentCompanyServices
  });
  const iossServicesHook = useIossServices({
    devMode: props.devMode,
    isAdmin: props.isAdmin,
    isSignup: props.isSignup,
    priceConfig: props.priceConfig,
    currentCompanyServices: props.currentCompanyServices
  });
  const ossServicesHook = useOssServices({
    devMode: props.devMode,
    isAdmin: props.isAdmin,
    isSignup: props.isSignup,
    priceConfig: props.priceConfig,
    currentCompanyServices: props.currentCompanyServices,
    euCountries: props.euCountries,
    countryOfEstablishment: props.countryOfEstablishment,
    checkVatNumber: props.checkVatNumber
  });
  const globalServicesHook = useGlobalServices({
    devMode: props.devMode,
    isAdmin: props.isAdmin,
    isSignup: props.isSignup,
    priceConfig: props.priceConfig,
    currentCompanyServices: props.currentCompanyServices
  });
  const state = useMemo(() => {
    const vm = {
      iossService: iossServicesHook.iossService,
      iossSelection: iossServicesHook.iossSelection,
      ossService: ossServicesHook.ossService,
      ossSelection: ossServicesHook.ossSelection,
      vatCountrySelection: countrySelectorHook.vatCountrySelection,
      vatServices: vatServicesHook.vatServices,
      globalService: globalServicesHook.globalService,
      ukEori: vatServicesHook.ukEori,
      euEori: vatServicesHook.euEori
    };
    return vm;
  }, [
    iossServicesHook.iossService,
    iossServicesHook.iossSelection,
    ossServicesHook.ossService,
    ossServicesHook.ossSelection,
    countrySelectorHook.vatCountrySelection,
    vatServicesHook.vatServices,
    globalServicesHook.globalService,
    vatServicesHook.ukEori,
    vatServicesHook.euEori
  ]);
  const htmlAdditionalServices = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    return html`<div class="space-y-4">
			${areVatServicesEnabled() ? html`<div>${vatServicesHook.htmlVatServices()}</div>` : ""}
			${areIossServicesEnabled() && (((_a = iossServicesHook.iossSelection) == null ? void 0 : _a.tier) !== void 0 || ((_b = props.priceConfig.ioss) == null ? void 0 : _b.deregistration) && ((_e = (_d = (_c = props.currentCompanyServices) == null ? void 0 : _c.GlobalServices) == null ? void 0 : _d.IossService) == null ? void 0 : _e.Tier) !== void 0) ? html`${templateTitle(i18next.t("IOSS"))}${iossServicesHook.htmlIossDetails()}` : ""}
			${areOssServicesEnabled() && ((_f = ossServicesHook.ossSelection) == null ? void 0 : _f.countryCode) !== void 0 || ((_i = (_h = (_g = props.currentCompanyServices) == null ? void 0 : _g.GlobalServices) == null ? void 0 : _h.OssService) == null ? void 0 : _i.ComplianceValidTo) !== void 0 ? html`${templateTitle(i18next.t("OSS"))}${ossServicesHook.htmlOssDetails()}` : ""}
		</div>`;
  };
  const htmlMainSelector = () => {
    return html`<div class="space-y-8">
			${areVatServicesEnabled() ? html`<div>${templateTitle(i18next.t("Vat Registration and Compliance"))}${countrySelectorHook.htmlCountrySelector()}</div>` : ""}
			${areIossServicesEnabled() ? html`<div>${templateTitle(i18next.t("IOSS Registration and Compliance"))}${iossServicesHook.htmlIossSelector()}</div>` : ""}
			${areOssServicesEnabled() ? html`<div>${templateTitle(i18next.t("OSS Registration and Compliance"))}${ossServicesHook.htmlOssSelector()}</div>` : ""}
			${!areVatServicesEnabled() && !areIossServicesEnabled() ? html`<div class="mt-8">${props.loadingServices.count > 0 ? `${i18next.t("Loading")}...` : i18next.t("Currently no IOSS or VAT service is available.")}</div> ` : ""}
		</div>`;
  };
  const htmlIossSimple = () => {
    return areIossServicesEnabled() ? html`<div>${templateTitle(i18next.t("IOSS Registration and Compliance"))}${iossServicesHook.htmlIossSelector()}${iossServicesHook.htmlIossDetails()}</div>` : html``;
  };
  const htmlWrap = (template) => {
    return html`<div class="md:flex md:justify-between m-auto">
			<div class="w-full">${template()}</div>
			<div class="lg:w-1/4 pt-8 md:ml-8">
				<div style="min-width:260px" class="border border-gray-400 rounded-xl p-4">${props.priceBreakdownTemplate()}</div>
			</div>
		</div>`;
  };
  const isValidMainSelector = () => {
    if (!props.isSignup) {
      return true;
    }
    return !areVatServicesEnabled() || countrySelectorHook.isValidCountrySelector || !areIossServicesEnabled() || iossServicesHook.isValidIossSelection || !areOssServicesEnabled() || ossServicesHook.isValidOssSelection;
  };
  const isValidAdditionalServices = () => {
    if (!props.isSignup) {
      return vatServicesHook.isValidVatServices && iossServicesHook.isValidIossService && ossServicesHook.isValidOssService;
    }
    return (!areVatServicesEnabled() || vatServicesHook.isValidVatServices) && (!areIossServicesEnabled() || iossServicesHook.isValidIossService) && (!areOssServicesEnabled() || ossServicesHook.isValidOssService);
  };
  const isValidGlobalServices = () => {
    return !props.isAdmin || globalServicesHook.isValidGlobalServices;
  };
  return {
    htmlMainSelector: () => htmlWrap(htmlMainSelector),
    htmlAdditionalServices: () => htmlWrap(htmlAdditionalServices),
    htmlIossSimple: () => htmlWrap(htmlIossSimple),
    htmlGlobalServices: () => htmlWrap(globalServicesHook.htmlGlobalServices),
    state,
    isValidMainSelector: isValidMainSelector(),
    isValidAdditionalServices: isValidAdditionalServices(),
    isValidGlobalServices: isValidGlobalServices(),
    isValid: isValidMainSelector() && isValidAdditionalServices() && isValidGlobalServices()
  };
};
