var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { TaxCodeDefinition } from "./../models/V1/TaxEngine/TaxCodeDefinition";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResult } from "./../models/V1/TaxEngine/Search/SearchResult";
export class TaxCodeDefinitions {
  /**
   * Creates an instance of TaxCodeDefinitions.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof TaxCodeDefinitions
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetTaxCodeByStringRq_ _getTaxCodeByStringRq
   * @returns Promise<GetTaxCodeByStringRq_>
   * @memberof TaxCodeDefinitions
   */
  getTaxCodeByString(pGetTaxCodeByStringRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/TaxCodeDefinitions/QueryByCodeStr/{returnType}-{taxCodeStr}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "returnType", value: pGetTaxCodeByStringRq.returnType, required: true },
      { name: "taxCodeStr", value: pGetTaxCodeByStringRq.taxCodeStr, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TaxCodeDefinition },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchTaxCodesRq_ _searchTaxCodesRq
   * @returns Promise<SearchTaxCodesRq_>
   * @memberof TaxCodeDefinitions
   */
  searchTaxCodes(pSearchTaxCodesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/TaxCodeDefinitions/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchTaxCodesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ToggleEnabledRq_ _toggleEnabledRq
   * @returns Promise<ToggleEnabledRq_>
   * @memberof TaxCodeDefinitions
   */
  toggleEnabled(pToggleEnabledRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/TaxCodeDefinitions/{taxCodeDefId}/Enabled",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "taxCodeDefId", value: pToggleEnabledRq.taxCodeDefId, required: true }
    ]);
    client.addBody({ value: pToggleEnabledRq.body, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddTaxCodeDefRq_ _addTaxCodeDefRq
   * @returns Promise<AddTaxCodeDefRq_>
   * @memberof TaxCodeDefinitions
   */
  addTaxCodeDef(pAddTaxCodeDefRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/TaxCodeDefinitions",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pAddTaxCodeDefRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: TaxCodeDefinition },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateTaxCodeDefRq_ _updateTaxCodeDefRq
   * @returns Promise<UpdateTaxCodeDefRq_>
   * @memberof TaxCodeDefinitions
   */
  updateTaxCodeDef(pUpdateTaxCodeDefRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/TaxCodeDefinitions/{taxCodeDefId}",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "taxCodeDefId", value: pUpdateTaxCodeDefRq.taxCodeDefId, required: true }
    ]);
    client.addBody({ value: pUpdateTaxCodeDefRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var GetTaxCodeByStringReturnType = /* @__PURE__ */ ((GetTaxCodeByStringReturnType2) => {
  GetTaxCodeByStringReturnType2["VATOrOSS"] = "VATOrOSS";
  GetTaxCodeByStringReturnType2["IOSS"] = "IOSS";
  return GetTaxCodeByStringReturnType2;
})(GetTaxCodeByStringReturnType || {});
