var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { getSupportedCurrencies } from "GlobalShared/helpers/complianceMasterDataHelper";
import { getBirthCountries, getEuCountries, getServiceCountries, getWorldCountries } from "GlobalShared/helpers/masterDataHelper";
import { MasterData as MasterDataClient } from "WebServices/APIs/self/clients/MasterData";
export const useWorldCountries = (locale) => {
  const [worldCountries, setWorldCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const countries = yield getWorldCountries(masterDataClient, locale);
    setWorldCountries(countries);
  });
  useEffect(init, []);
  return worldCountries;
};
export const useEUCountries = (locale) => {
  const [euCountries, setEuCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const euCountriesResponse = yield getEuCountries(masterDataClient, locale);
    setEuCountries(euCountriesResponse);
  });
  useEffect(init, []);
  return euCountries;
};
export const useServiceCountries = (locale) => {
  const [serviceCountries, setServiceCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const servieCountriesResponse = yield getServiceCountries(masterDataClient, locale);
    setServiceCountries(servieCountriesResponse);
  });
  useEffect(init, []);
  return serviceCountries;
};
export const useBirthCountries = (locale) => {
  const [birthCountries, setBirthCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const birthCountriesResponse = yield getBirthCountries(masterDataClient, locale);
    setBirthCountries(birthCountriesResponse);
  });
  useEffect(init, []);
  return birthCountries;
};
export const useSupportedCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const currenciesResponse = yield getSupportedCurrencies(masterDataClient);
    setCurrencies(currenciesResponse);
  });
  useEffect(init, []);
  return currencies;
};
export const useEnglishWorldCountries = () => {
  const englishWorldCountries = useWorldCountries("en-GB");
  return englishWorldCountries;
};
