var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import i18next from "i18next";
import { circleCheckedIcon, fileIcon, templateTitle, triangleExclamationIcon } from "GlobalShared/templates/commons";
import { FormatTypeMapping } from "./CustomDataTemplateHelper";
import classNames from "classnames";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
export function useTestStep(props) {
  const [saleFileInfo, setSaleFileInfo] = useState(void 0);
  const [purchaseFileInfo, setPurchaseFileInfo] = useState(void 0);
  const [oneUploadedFileInfo, setOneUploadedFileInfo] = useState(void 0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showFileErrorsFor, setShowFileErrorsFor] = useState(void 0);
  const linkGeneratorExportSupportedCurrencies = `${window.location.origin}/api/v1/MasterData/ExportSupportedCurrencies`;
  useEffect(() => {
    setSaleFileInfo(void 0);
    setPurchaseFileInfo(void 0);
    setOneUploadedFileInfo(void 0);
  }, [props.generationConfiguration, props.currentConfiguration]);
  const uploadedFileTemplate = (uploadResult, uploadType) => {
    var _a, _b;
    return html`
		<div class="flex flex-col">
			<div class="flex gap-3">
				<div class="cursor-pointer" title=${uploadResult.fileName}>${fileIcon(uploadResult.fileName)}</div>
				<div class="text-sm truncate hover:underline cursor-pointer" title=${uploadResult.fileName}>${uploadResult.fileName}</div>
				<div>${((_a = uploadResult == null ? void 0 : uploadResult.validationResult) == null ? void 0 : _a.IsSuccess) ? circleCheckedIcon() : triangleExclamationIcon()}</div>
			</div>
			${!((_b = uploadResult == null ? void 0 : uploadResult.validationResult) == null ? void 0 : _b.IsSuccess) ? html`<div
						class="text-sm text-red-500 hover:underline cursor-pointer max-w-fit ml-9"
						@click=${() => {
      setShowErrorModal(true);
      setShowFileErrorsFor(uploadType);
    }}
				  >
						${i18next.t("Validation error, view details")}
				  </div>` : ""}
		</div>
	`;
  };
  const separateFileUploadTemplate = () => {
    var _a, _b;
    return html`
			<div class="flex flex-col gap-8">
				<div class="flex flex-col gap-4">
					<div class="text-md font-semibold">${i18next.t("Sale")}</div>
					<div class="flex gap-4 flex-wrap items-center">
						<dc-fileupload2
							class="-ml-3"
							.withUploadIcon=${true}
							.label=${i18next.t("Upload")}
							.accept=${FormatTypeMapping.get((_a = props.currentConfiguration) == null ? void 0 : _a.AvailableTemplates[0])}
							@upload=${(e) => __async(this, null, function* () {
      const validationResult = yield props.getValidationResult(
        "Sale",
        e.detail.files[0],
        props.loader,
        props.selectedCountryTab,
        props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne",
        props.generationConfiguration.CompanyId
      );
      setSaleFileInfo({ fileName: e.detail.files[0].name, validationResult });
    })}
						></dc-fileupload2>
						${saleFileInfo ? uploadedFileTemplate(saleFileInfo, "Sale") : ""}
					</div>
				</div>
				<div class="flex flex-col gap-4">
					<div class="text-md font-semibold">${i18next.t("Purchase")}</div>
					<div class="flex gap-4 flex-wrap items-center">
						<dc-fileupload2
							class="-ml-3"
							.withUploadIcon=${true}
							.label=${i18next.t("Upload")}
							.accept=${FormatTypeMapping.get((_b = props.currentConfiguration) == null ? void 0 : _b.AvailableTemplates[0])}
							@upload=${(e) => __async(this, null, function* () {
      const validationResult = yield props.getValidationResult(
        "Purchase",
        e.detail.files[0],
        props.loader,
        props.selectedCountryTab,
        props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne",
        props.generationConfiguration.CompanyId
      );
      setPurchaseFileInfo({ fileName: e.detail.files[0].name, validationResult });
    })}
						></dc-fileupload2>
						${purchaseFileInfo ? uploadedFileTemplate(purchaseFileInfo, "Purchase") : ""}
					</div>
				</div>
			</div>
		`;
  };
  const oneFileUploadTemplate = () => {
    var _a;
    return html`
		<div class="flex flex-col gap-4">
			<div class="text-md font-semibold">${i18next.t("Upload file")}</div>
			<div class="flex gap-4 flex-wrap items-center">
				<dc-fileupload2
					class="-ml-3"
					.withUploadIcon=${true}
					.label=${i18next.t("Upload")}
					.accept=${FormatTypeMapping.get((_a = props.currentConfiguration) == null ? void 0 : _a.AvailableTemplates[0])}
					@upload=${(e) => __async(this, null, function* () {
      const validationResult = yield props.getValidationResult(
        "AllInOne",
        e.detail.files[0],
        props.loader,
        props.selectedCountryTab,
        props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne",
        props.generationConfiguration.CompanyId
      );
      setOneUploadedFileInfo({ fileName: e.detail.files[0].name, validationResult });
    })}
				></dc-fileupload2>
				${oneUploadedFileInfo ? uploadedFileTemplate(oneUploadedFileInfo, "AllInOne") : ""}
			</div>
		</div>
	`;
  };
  const fileUploadTemplate = () => {
    var _a;
    return ((_a = props.currentConfiguration) == null ? void 0 : _a.SalePurchaseSeparately) ? separateFileUploadTemplate() : oneFileUploadTemplate();
  };
  const uploadedFileValidatonErrorsTemplate = (uploadResult, uploadType, title) => {
    var _a, _b;
    return ((_b = (_a = uploadResult == null ? void 0 : uploadResult.validationResult) == null ? void 0 : _a.ValidationMessages) == null ? void 0 : _b.length) > 0 && showFileErrorsFor === uploadType ? html`
					<div class="text-xl font-semibold mb-4">${title}</div>
					<div class="flex flex-col gap-4">
						${uploadResult.validationResult.ValidationMessages.map(
      (error, index) => {
        var _a2, _b2, _c;
        return html`
									<div
										class=${classNames("flex flex-col flex-wrap gap-4 pb-4", {
          "border-b-2 border-solid": index !== uploadResult.validationResult.ValidationMessages.length - 1
        })}
									>
										<div class=${classNames("flex gap-1 flex-wrap", { hidden: !error.FieldName })}>
											<span class="font-semibold">${i18next.t("Field name")}:</span><span>${(_a2 = error.FieldName) != null ? _a2 : ""}</span>
										</div>
										<div class=${classNames("flex gap-1 flex-wrap", { hidden: !error.ValidationCode })}>
											<span class="font-semibold">${i18next.t("Validation code")}:</span><span>${(_b2 = error.ValidationCode) != null ? _b2 : ""}</span>
										</div>
										<div class=${classNames("flex gap-1 flex-wrap", { hidden: !error.RowNumber })}>
											<span class="font-semibold">${i18next.t("Row number")}:</span><span>${(_c = error.RowNumber) != null ? _c : ""}</span>
										</div>
										${error.FieldType === "Currency" ? html` <div class=${classNames("flex gap-1 flex-wrap")}>
													<span class="font-semibold">${i18next.t("Message")}:</span>
													<span>
														${unsafeHTML(
          i18next.t(
            "supported_currencies_error_message",
            "We identified in the data a currency that is currently not supported. Please {{- link_start}}Click here{{- link_end}} for a list of currencies that are supported.",
            {
              link_start: `<a class="underline" href="${linkGeneratorExportSupportedCurrencies}" target="_blank">`,
              link_end: "</a>"
            }
          )
        )}
													</span>
											  </div>` : html``}
									</div>
								`;
      }
    )}
					</div>
			  ` : "";
  };
  const modalContentTemplate = () => html`
		<div class="px-8 py-6">
			<div class="flex flex-col gap-4">
				${uploadedFileValidatonErrorsTemplate(oneUploadedFileInfo, "AllInOne", i18next.t("Errors in uploaded file"))}
				${uploadedFileValidatonErrorsTemplate(saleFileInfo, "Sale", i18next.t("Sale file errors"))}
				${uploadedFileValidatonErrorsTemplate(purchaseFileInfo, "Purchase", i18next.t("Purchase file errors"))}
			</div>
		</div>
	`;
  const errorModalTemplate = () => html`
		<dc-modal
			.visible=${showErrorModal}
			@close=${() => {
    setShowErrorModal(false);
    setShowFileErrorsFor(void 0);
  }}
			.header=${"Validation errors"}
			.content=${modalContentTemplate()}
		>
		</dc-modal>
	`;
  const template = () => {
    var _a;
    return html`
		<div class="m-8 flex flex-col gap-4">
			<div class="flex flex-col gap-2">
				${templateTitle(i18next.t("Test"))}
				${((_a = props.currentConfiguration) == null ? void 0 : _a.SalePurchaseSeparately) ? html`<div>${i18next.t("Upload your files to see if they are valid or not.")}</div>` : html`<div>${i18next.t("Upload your file to see if it is valid or not.")}</div>`}
			</div>
			${props.generationConfiguration && props.currentConfiguration ? fileUploadTemplate() : ""}
			<div class="flex justify-start mt-16">${SecondaryButton({ class: "max-w-fit", text: i18next.t("Previous"), onClick: () => props.setStep(3) })}</div>
		</div>
		${errorModalTemplate()}
	`;
  };
  return { template };
}
