var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class UpdateTaxCode {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientTaxCode");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "OutOfScope");
    /**
     */
    __publicField(this, "ReverseCharge");
    /**
     */
    __publicField(this, "TransactionTypeCode");
    /**
     */
    __publicField(this, "TypeOfGoods");
    /**
     */
    __publicField(this, "VatRateType");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdateTaxCode
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.TaxCode.UpdateTaxCode");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientTaxCode = _data["ClientTaxCode"];
      this.CountryCode = _data["CountryCode"];
      this.OutOfScope = _data["OutOfScope"];
      this.ReverseCharge = _data["ReverseCharge"];
      this.TransactionTypeCode = _data["TransactionTypeCode"];
      this.TypeOfGoods = _data["TypeOfGoods"];
      this.VatRateType = UpdateTaxCodeVatRateType[_data["VatRateType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientTaxCode"] = this.ClientTaxCode;
    _data["CountryCode"] = this.CountryCode;
    _data["OutOfScope"] = this.OutOfScope;
    _data["ReverseCharge"] = this.ReverseCharge;
    _data["TransactionTypeCode"] = this.TransactionTypeCode;
    _data["TypeOfGoods"] = this.TypeOfGoods;
    _data["VatRateType"] = UpdateTaxCodeVatRateType[this.VatRateType];
    return _data;
  }
}
export var UpdateTaxCodeVatRateType = /* @__PURE__ */ ((UpdateTaxCodeVatRateType2) => {
  UpdateTaxCodeVatRateType2["Standard"] = "Standard";
  UpdateTaxCodeVatRateType2["Standard2"] = "Standard2";
  UpdateTaxCodeVatRateType2["Reduced"] = "Reduced";
  UpdateTaxCodeVatRateType2["SuperReduced"] = "SuperReduced";
  UpdateTaxCodeVatRateType2["SuperReduced2"] = "SuperReduced2";
  UpdateTaxCodeVatRateType2["Exempt"] = "Exempt";
  UpdateTaxCodeVatRateType2["ZeroRated"] = "ZeroRated";
  UpdateTaxCodeVatRateType2["Middle"] = "Middle";
  UpdateTaxCodeVatRateType2["Reduced2"] = "Reduced2";
  return UpdateTaxCodeVatRateType2;
})(UpdateTaxCodeVatRateType || {});
