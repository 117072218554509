var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { PeriodSearchResponse } from "./../models/V1/Cesop/Periods/Search/PeriodSearchResponse";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { Period } from "./../models/V1/Cesop/Periods/Period";
import { CreateFileResult } from "./../models/V1/Cesop/Periods/CreateFileResult";
import { ValidationErrorCollection } from "./../models/V1/Cesop/Periods/Validation/Source/ValidationErrorCollection";
export class Periods {
  /**
   * Creates an instance of Periods.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Periods
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Periods
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/search",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PeriodSearchResponse },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPeriodRq_ _getPeriodRq
   * @returns Promise<GetPeriodRq_>
   * @memberof Periods
   */
  getPeriod(pGetPeriodRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}",
      "GET",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pGetPeriodRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Period },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeUploadTypeRq_ _changeUploadTypeRq
   * @returns Promise<ChangeUploadTypeRq_>
   * @memberof Periods
   */
  changeUploadType(pChangeUploadTypeRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pChangeUploadTypeRq.id, required: true }
    ]);
    client.addBody({ value: pChangeUploadTypeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeUploadRq_ _finalizeUploadRq
   * @returns Promise<FinalizeUploadRq_>
   * @memberof Periods
   */
  finalizeUpload(pFinalizeUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/finalize",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pFinalizeUploadRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateSourceFileRq_ _createSourceFileRq
   * @returns Promise<CreateSourceFileRq_>
   * @memberof Periods
   */
  createSourceFile(pCreateSourceFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/sourcefiles",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pCreateSourceFileRq.id, required: true }
    ]);
    client.addBody({ value: pCreateSourceFileRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: CreateFileResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof Periods
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/files/{fileReference}",
      "GET",
      "application/json; charset=UTF-8",
      "text/xml, text/csv, application/pdf",
      options
    );
    client.addPathParams([
      { name: "id", value: pDownloadFileRq.id, required: true },
      { name: "fileReference", value: pDownloadFileRq.fileReference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ApproveSummaryRq_ _approveSummaryRq
   * @returns Promise<ApproveSummaryRq_>
   * @memberof Periods
   */
  approveSummary(pApproveSummaryRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/approve",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pApproveSummaryRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RejectSummaryRq_ _rejectSummaryRq
   * @returns Promise<RejectSummaryRq_>
   * @memberof Periods
   */
  rejectSummary(pRejectSummaryRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/reject",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pRejectSummaryRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PayeeSourceFileUploadedRq_ _payeeSourceFileUploadedRq
   * @returns Promise<PayeeSourceFileUploadedRq_>
   * @memberof Periods
   */
  payeeSourceFileUploaded(pPayeeSourceFileUploadedRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/payees",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pPayeeSourceFileUploadedRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreatePayeesSourceFileRq_ _createPayeesSourceFileRq
   * @returns Promise<CreatePayeesSourceFileRq_>
   * @memberof Periods
   */
  createPayeesSourceFile(pCreatePayeesSourceFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/payees",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pCreatePayeesSourceFileRq.id, required: true }
    ]);
    client.addBody({ value: pCreatePayeesSourceFileRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: CreateFileResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeletePayeeSourceFileRq_ _deletePayeeSourceFileRq
   * @returns Promise<DeletePayeeSourceFileRq_>
   * @memberof Periods
   */
  deletePayeeSourceFile(pDeletePayeeSourceFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/payees",
      "DELETE",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pDeletePayeeSourceFileRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPayeeValidationErrorsRq_ _getPayeeValidationErrorsRq
   * @returns Promise<GetPayeeValidationErrorsRq_>
   * @memberof Periods
   */
  getPayeeValidationErrors(pGetPayeeValidationErrorsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/payees/ValidationErrors",
      "GET",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "id", value: pGetPayeeValidationErrorsRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ValidationErrorCollection },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTransactionValidationErrorsRq_ _getTransactionValidationErrorsRq
   * @returns Promise<GetTransactionValidationErrorsRq_>
   * @memberof Periods
   */
  getTransactionValidationErrors(pGetTransactionValidationErrorsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{periodId}/sourceFiles/{sourceFileId}/ValidationErrors",
      "GET",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "periodId", value: pGetTransactionValidationErrorsRq.periodId, required: true },
      { name: "sourceFileId", value: pGetTransactionValidationErrorsRq.sourceFileId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ValidationErrorCollection },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadProofFilesRq_ _downloadProofFilesRq
   * @returns Promise<DownloadProofFilesRq_>
   * @memberof Periods
   */
  downloadProofFiles(pDownloadProofFilesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/SubmissionProofFiles",
      "GET",
      "application/json; charset=UTF-8",
      "application/zip",
      options
    );
    client.addPathParams([
      { name: "id", value: pDownloadProofFilesRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
