var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { CompanyDetail } from "./../models/V1/Company/CompanyInfo/CompanyDetail";
import { Subscription } from "./../models/V1/CompanyInfo/Subscriptions/Subscription";
import { SubscriptionFactory } from "./../models/V1/CompanyInfo/Subscriptions/SubscriptionFactory";
import { SearchResult } from "./../models/V1/Company/Search/SearchResult";
export class Companies {
  /**
   * Creates an instance of Companies.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Companies
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param UpdateSubscriptionDatesRq_ _updateSubscriptionDatesRq
   * @returns Promise<UpdateSubscriptionDatesRq_>
   * @memberof Companies
   */
  updateSubscriptionDates(pUpdateSubscriptionDatesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/subscriptions/{subscriptionId}",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateSubscriptionDatesRq.companyId, required: true },
      { name: "subscriptionId", value: pUpdateSubscriptionDatesRq.subscriptionId, required: true }
    ]);
    client.addBody({ value: pUpdateSubscriptionDatesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CancelSubscriptionRq_ _cancelSubscriptionRq
   * @returns Promise<CancelSubscriptionRq_>
   * @memberof Companies
   */
  cancelSubscription(pCancelSubscriptionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/subscriptions/{subscriptionId}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pCancelSubscriptionRq.companyId, required: true },
      { name: "subscriptionId", value: pCancelSubscriptionRq.subscriptionId, required: true }
    ]);
    client.addBody({ value: pCancelSubscriptionRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof Companies
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/aml",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadFileRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCompanyDetailsRq_ _getCompanyDetailsRq
   * @returns Promise<GetCompanyDetailsRq_>
   * @memberof Companies
   */
  getCompanyDetails(pGetCompanyDetailsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetCompanyDetailsRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CompanyDetail },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCompanySubscriptionsRq_ _getCompanySubscriptionsRq
   * @returns Promise<GetCompanySubscriptionsRq_>
   * @memberof Companies
   */
  getCompanySubscriptions(pGetCompanySubscriptionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/subscriptions",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetCompanySubscriptionsRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Subscription, isArray: true, factory: SubscriptionFactory },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasPendingBankTransferPaymentRq_ _hasPendingBankTransferPaymentRq
   * @returns Promise<HasPendingBankTransferPaymentRq_>
   * @memberof Companies
   */
  hasPendingBankTransferPayment(pHasPendingBankTransferPaymentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/payments/pending",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pHasPendingBankTransferPaymentRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param InactivateCompanyRq_ _inactivateCompanyRq
   * @returns Promise<InactivateCompanyRq_>
   * @memberof Companies
   */
  inactivateCompany(pInactivateCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/Inactivate",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pInactivateCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RejectCompanyRq_ _rejectCompanyRq
   * @returns Promise<RejectCompanyRq_>
   * @memberof Companies
   */
  rejectCompany(pRejectCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/Reject",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pRejectCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ReopenCompanyRq_ _reopenCompanyRq
   * @returns Promise<ReopenCompanyRq_>
   * @memberof Companies
   */
  reopenCompany(pReopenCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/ReOpen",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pReopenCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RevalidateVatNumberRq_ _revalidateVatNumberRq
   * @returns Promise<RevalidateVatNumberRq_>
   * @memberof Companies
   */
  revalidateVatNumber(pRevalidateVatNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/vatnumber/revalidate",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pRevalidateVatNumberRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchCompaniesRq_ _searchCompaniesRq
   * @returns Promise<SearchCompaniesRq_>
   * @memberof Companies
   */
  searchCompanies(pSearchCompaniesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchCompaniesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAdministratorRq_ _updateAdministratorRq
   * @returns Promise<UpdateAdministratorRq_>
   * @memberof Companies
   */
  updateAdministrator(pUpdateAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/administrators",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAdministratorRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateAdministratorRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAntiMoneyLaunderingRq_ _updateAntiMoneyLaunderingRq
   * @returns Promise<UpdateAntiMoneyLaunderingRq_>
   * @memberof Companies
   */
  updateAntiMoneyLaundering(pUpdateAntiMoneyLaunderingRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/aml/update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAntiMoneyLaunderingRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateAntiMoneyLaunderingRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateBusinessDetailRq_ _updateBusinessDetailRq
   * @returns Promise<UpdateBusinessDetailRq_>
   * @memberof Companies
   */
  updateBusinessDetail(pUpdateBusinessDetailRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/BusinessDetail",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateBusinessDetailRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateBusinessDetailRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateClientAcceptanceRq_ _updateClientAcceptanceRq
   * @returns Promise<UpdateClientAcceptanceRq_>
   * @memberof Companies
   */
  updateClientAcceptance(pUpdateClientAcceptanceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/ClientAcceptance/Update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateClientAcceptanceRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateClientAcceptanceRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateInvoicingRq_ _updateInvoicingRq
   * @returns Promise<UpdateInvoicingRq_>
   * @memberof Companies
   */
  updateInvoicing(pUpdateInvoicingRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/invoicing",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "isEnabled", value: pUpdateInvoicingRq.isEnabled }
    ]);
    client.addPathParams([
      { name: "companyId", value: pUpdateInvoicingRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateNotesRq_ _updateNotesRq
   * @returns Promise<UpdateNotesRq_>
   * @memberof Companies
   */
  updateNotes(pUpdateNotesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/notes",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "notes", value: pUpdateNotesRq.notes }
    ]);
    client.addPathParams([
      { name: "companyId", value: pUpdateNotesRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadAmlDocumentRq_ _uploadAmlDocumentRq
   * @returns Promise<UploadAmlDocumentRq_>
   * @memberof Companies
   */
  uploadAmlDocument(pUploadAmlDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/{companyId}/aml/upload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadAmlDocumentRq.companyId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadAmlDocumentRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
