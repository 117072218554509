var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { ComplianceUpload } from "./../models/V1/ComplianceUploads/ComplianceUpload";
import { StatusResult } from "./../models/V1/ComplianceUploads/StatusResult";
import { SearchResult } from "./../models/V1/ComplianceUploads/Search/SearchResult";
export class ComplianceUploads {
  /**
   * Creates an instance of ComplianceUploads.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceUploads
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CancelRq_ _cancelRq
   * @returns Promise<CancelRq_>
   * @memberof ComplianceUploads
   */
  cancel(pCancelRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Cancel",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pCancelRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeIgnoreOverdueRq_ _changeIgnoreOverdueRq
   * @returns Promise<ChangeIgnoreOverdueRq_>
   * @memberof ComplianceUploads
   */
  changeIgnoreOverdue(pChangeIgnoreOverdueRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/IgnoreOverdue",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "newValue", value: pChangeIgnoreOverdueRq.newValue }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pChangeIgnoreOverdueRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof ComplianceUploads
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pChangeStatusRq.complianceUploadId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CheckBalanceRq_ _checkBalanceRq
   * @returns Promise<CheckBalanceRq_>
   * @memberof ComplianceUploads
   */
  checkBalance(pCheckBalanceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/CheckBalance",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pCheckBalanceRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetRq_ _getRq
   * @returns Promise<GetRq_>
   * @memberof ComplianceUploads
   */
  get(pGetRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGetRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ComplianceUpload },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteRq_ _deleteRq
   * @returns Promise<DeleteRq_>
   * @memberof ComplianceUploads
   */
  delete(pDeleteRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pDeleteRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof ComplianceUploads
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Files/{reference}",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pDownloadFileRq.complianceUploadId, required: true },
      { name: "reference", value: pDownloadFileRq.reference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportRq_ _exportRq
   * @returns Promise<ExportRq_>
   * @memberof ComplianceUploads
   */
  export(pExportRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/export",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv, application/json",
      options
    );
    client.addBody({ value: pExportRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeIossRq_ _finalizeIossRq
   * @returns Promise<FinalizeIossRq_>
   * @memberof ComplianceUploads
   */
  finalizeIoss(pFinalizeIossRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeIoss",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeIossRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeMasterDataRq_ _finalizeMasterDataRq
   * @returns Promise<FinalizeMasterDataRq_>
   * @memberof ComplianceUploads
   */
  finalizeMasterData(pFinalizeMasterDataRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeMasterData",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeMasterDataRq.complianceUploadId, required: true }
    ]);
    client.addBody({ value: pFinalizeMasterDataRq.body, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeSAFTRq_ _finalizeSAFTRq
   * @returns Promise<FinalizeSAFTRq_>
   * @memberof ComplianceUploads
   */
  finalizeSAFT(pFinalizeSAFTRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeSAFT",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "confirmNoSaft", value: pFinalizeSAFTRq.confirmNoSaft }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeSAFTRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeVatRq_ _finalizeVatRq
   * @returns Promise<FinalizeVatRq_>
   * @memberof ComplianceUploads
   */
  finalizeVat(pFinalizeVatRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeVat",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeVatRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GeneratePeriodsRq_ _generatePeriodsRq
   * @returns Promise<GeneratePeriodsRq_>
   * @memberof ComplianceUploads
   */
  generatePeriods(pGeneratePeriodsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/companies/{companyId}",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGeneratePeriodsRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetSaftTemplateRq_ _getSaftTemplateRq
   * @returns Promise<GetSaftTemplateRq_>
   * @memberof ComplianceUploads
   */
  getSaftTemplate(pGetSaftTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/SaftTemplate",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGetSaftTemplateRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTextFileRq_ _getTextFileRq
   * @returns Promise<GetTextFileRq_>
   * @memberof ComplianceUploads
   */
  getTextFile(pGetTextFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Text",
      "GET",
      "application/json; charset=UTF-8",
      "text/tab-separated-values, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGetTextFileRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedAmazonTransactionsRq_ _removeUploadedAmazonTransactionsRq
   * @returns Promise<RemoveUploadedAmazonTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedAmazonTransactions(pRemoveUploadedAmazonTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/Amazon",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedAmazonTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedVatMpTransactionsRq_ _removeUploadedVatMpTransactionsRq
   * @returns Promise<RemoveUploadedVatMpTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedVatMpTransactions(pRemoveUploadedVatMpTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/VAT",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedVatMpTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof ComplianceUploads
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
