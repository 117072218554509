var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { DEFAULT_COUNTRY_OPTION, DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT } from "./CustomDataTemplateHelper";
import { useConfirmModal } from "../modals/commonModals";
export function useCountrySelectorStep(props) {
  const confirmModal = useConfirmModal();
  const onTransactionsInputChange = (e) => __async(this, null, function* () {
    if (e.detail.value && // All country in one
    (yield confirmModal.confirm(
      i18next.t(
        "Please note that you have switched to \u201CAll Country\u201D setting. If you confirm, we will delete the country templates you saved previously, and require you to create a new template for all countries. Do you confirm?"
      )
    )) || !e.detail.value) {
      yield props.updateCustomDataTemplate(
        { companyId: props.generationConfiguration.CompanyId, defaultCountryTemplate: DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT },
        e.detail.value ? void 0 : DEFAULT_COUNTRY_OPTION,
        e.detail.value,
        props.loader
      );
      yield props.getAndSetGenerationConfiguration();
    }
  });
  const transactionsInputTemplate = () => {
    var _a;
    return html`
		<dc-multiswitch
			.label=${i18next.t("Will you provide transactions by country or all country transactions in one file?")}
			.customLabelClass=${"block"}
			.dataSource=${[
      { label: i18next.t("By country"), value: false },
      { label: i18next.t("All country"), value: true }
    ]}
			.selectedItem=${((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne"}
			@change=${onTransactionsInputChange}
		></dc-multiswitch>
	`;
  };
  const countryChip = (countryCode) => {
    var _a;
    return html`
			<span class="mb-2 mr-2 py-2 px-4 rounded-full text-xs border border-solid border-brand-primary whitespace-nowrap inline-flex items-center bg-white text-brand-primary">
				${(_a = props.serviceCountries.find((c) => c.Code === countryCode)) == null ? void 0 : _a.Name}
				${countryCode !== DEFAULT_COUNTRY_OPTION ? html`<span
							class="ml-2 text-xs cursor-pointer font-semibold text-black bg-gray-400 rounded-full px-1"
							@click=${() => __async(this, null, function* () {
      var _a2;
      if (yield confirmModal.confirm(
        i18next.t("Are you sure you want to remove this country").concat(`: ${(_a2 = props.serviceCountries.find((c) => c.Code === countryCode)) == null ? void 0 : _a2.Name}?`)
      )) {
        yield props.deleteCustomSourceCountryConfiguration(countryCode, props.loader);
        yield props.getAndSetGenerationConfiguration();
      }
    })}
							>&#10005;</span
					  >` : ""}
			</span>
		`;
  };
  const countrySelector = () => {
    var _a;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" ? "" : html`
					<dc-select
						class="max-w-[400px]"
						.filterable=${true}
						.dataSource=${props.serviceCountries.filter(
      (rc) => {
        var _a2, _b, _c;
        return !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.CountryConfigurations.map((c) => c.CountryCode).includes(rc.Code)) || ((_c = (_b = props.generationConfiguration) == null ? void 0 : _b.CountryConfigurations.find((c) => c.CountryCode === rc.Code)) == null ? void 0 : _c.IsDeleted);
      }
    ).map((c) => ({ label: c.Name, value: c.Code }))}
						.label=${i18next.t("Country")}
						@change=${(e) => __async(this, null, function* () {
      var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
      e.preventDefault();
      e.stopPropagation();
      if (props.generationConfiguration.CountryConfigurations.some((c) => c.CountryCode === e.detail.selectedValue && !c.IsDeleted))
        return;
      const countryConfig = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CountryConfigurations.find((config) => config.CountryCode === e.detail.selectedValue);
      const createDataStepObject = {
        templateType: (_c = (_b = countryConfig == null ? void 0 : countryConfig.Configuration.AvailableTemplates) == null ? void 0 : _b.at(0)) != null ? _c : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.templateType,
        delimitier: (_e = (_d = countryConfig == null ? void 0 : countryConfig.Configuration.FormatSettings) == null ? void 0 : _d.Delimiter) != null ? _e : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.delimitier,
        decimalSeparator: (_g = (_f = countryConfig == null ? void 0 : countryConfig.Configuration.FormatSettings) == null ? void 0 : _f.DecimalSeparator) != null ? _g : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.decimalSeparator,
        hasHeaderLine: (_i = (_h = countryConfig == null ? void 0 : countryConfig.Configuration.FormatSettings) == null ? void 0 : _h.IncludesHeader) != null ? _i : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.hasHeaderLine,
        isSaleAndPurchaseInOneFile: !(countryConfig == null ? void 0 : countryConfig.Configuration.SalePurchaseSeparately),
        formatSettingsDateFormat: (_k = (_j = countryConfig == null ? void 0 : countryConfig.Configuration.FormatSettings) == null ? void 0 : _j.DateFormat) != null ? _k : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.formatSettingsDateFormat
      };
      const dataStepInfo = __spreadProps(__spreadValues({}, createDataStepObject), { countryCode: e.detail.selectedValue });
      const createDataStepInfo = { companyId: props.generationConfiguration.CompanyId, countryTemplates: [dataStepInfo] };
      yield props.updateCustomDataTemplate(
        createDataStepInfo,
        e.detail.selectedValue,
        ((_l = props.generationConfiguration) == null ? void 0 : _l.ComplianceUploadGenerationMode) === "AllCountryInOne",
        props.loader
      );
      yield props.getAndSetGenerationConfiguration();
    })}
					></dc-select>
			  `;
  };
  const countriesTemplate = () => {
    var _a, _b, _c, _d;
    return html`
		<div>
			${((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) !== "AllCountryInOne" && ((_b = props.generationConfiguration) == null ? void 0 : _b.CountryConfigurations.filter((c) => !c.IsDeleted).length) ? html`
						<div>
							<div class="font-bold whitespace-nowrap text-sm">${i18next.t("Selected countries")}</div>
							<div class="flex flex-wrap mt-1">
								${(_d = (_c = props.generationConfiguration) == null ? void 0 : _c.CountryConfigurations) == null ? void 0 : _d.map((c) => !c.IsDeleted ? html`<div class="mr-2">${countryChip(c.CountryCode)}</div>` : "")}
							</div>
						</div>
				  ` : ""}
		</div>
	`;
  };
  const nextButtonTemplate = () => html`
		<div class="flex justify-start">
			${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: () => {
      props.setSelectedCountry(DEFAULT_COUNTRY_OPTION);
      props.setStep(1);
    }
  })}
		</div>
	`;
  const template = () => html`
			<div class="m-8 flex flex-col gap-4">${templateTitle(i18next.t("Upload methods"))} ${transactionsInputTemplate()} ${countrySelector()} ${countriesTemplate()}${nextButtonTemplate()}</div>
			${confirmModal.mainTemplate()}
		`;
  return {
    template
  };
}
