import { FieldTransformation } from "./FieldTransformation";
import { SubstringFieldTransformation } from "./SubstringFieldTransformation";
export class FieldTransformationFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.FieldTransformation") {
      const result = new FieldTransformation();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.SubstringFieldTransformation") {
      const result = new SubstringFieldTransformation();
      return result;
    }
    throw new Error($type + " not found");
  }
}
