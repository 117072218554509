var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Psp } from "./../models/V1/Cesop/Psps/Psp";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PspSearchResponse } from "./../models/V1/Cesop/Psps/Search/PspSearchResponse";
export class Psps {
  /**
   * Creates an instance of Psps.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Psps
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetPspRq_ _getPspRq
   * @returns Promise<GetPspRq_>
   * @memberof Psps
   */
  getPsp(pGetPspRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Psps/{pspId}",
      "GET",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "pspId", value: pGetPspRq.pspId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Psp },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdatePspRq_ _updatePspRq
   * @returns Promise<UpdatePspRq_>
   * @memberof Psps
   */
  updatePsp(pUpdatePspRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Psps/{pspId}",
      "PUT",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "pspId", value: pUpdatePspRq.pspId, required: true }
    ]);
    client.addBody({ value: pUpdatePspRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreatePspRq_ _createPspRq
   * @returns Promise<CreatePspRq_>
   * @memberof Psps
   */
  createPsp(pCreatePspRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Psps",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addBody({ value: pCreatePspRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Psps
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Psps/search",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PspSearchResponse },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreatePeriodsRq_ _createPeriodsRq
   * @returns Promise<CreatePeriodsRq_>
   * @memberof Psps
   */
  createPeriods(pCreatePeriodsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Psps/{pspId}/periods",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "pspId", value: pCreatePeriodsRq.pspId, required: true }
    ]);
    client.addBody({ value: pCreatePeriodsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
