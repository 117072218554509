import {
  OneCustomSourceConfiguration,
  OneCustomSourceReference,
  SeparateCustomSourceConfiguration,
  SeparateCustomSourceReference
} from "WebServices/APIs/self/models/V1/GenerationConfiguration";
export const mapValidationResult = (validationResult) => ({
  IsSuccess: validationResult.IsSuccess,
  NumberOfTotalLinesWithError: validationResult.NumberOfTotalLinesWithError,
  ValidationMessages: validationResult.ValidationMessages.map((v) => ({
    FieldName: v.FieldName,
    FieldType: v.FieldType,
    RowNumber: v.RowNumber,
    TotalCount: v.TotalCount,
    ValidationCode: v.ValidationCode
  }))
});
const mapTemplateFileReference = (defaultConfiguration) => {
  if (defaultConfiguration.TemplateFileReference instanceof OneCustomSourceReference) {
    return {
      Reference: defaultConfiguration.TemplateFileReference.Reference,
      TransactionDataFields: defaultConfiguration.TemplateFileReference.TransactionData.Fields,
      FileName: defaultConfiguration.TemplateFileReference.FileName
    };
  } else if (defaultConfiguration.TemplateFileReference instanceof SeparateCustomSourceReference) {
    return {
      PurchaseReference: defaultConfiguration.TemplateFileReference.PurchaseReference,
      SaleReference: defaultConfiguration.TemplateFileReference.SaleReference,
      SeparateTransactionData: defaultConfiguration.TemplateFileReference.TransactionData,
      PurchaseFileName: defaultConfiguration.TemplateFileReference.PurchaseFileName,
      SaleFileName: defaultConfiguration.TemplateFileReference.SaleFileName
    };
  }
  return {};
};
const mapCustomSourceConfiguration = (defaultConfiguration) => {
  if (defaultConfiguration.CustomSourceConfiguration instanceof OneCustomSourceConfiguration) {
    return {
      OneMapping: defaultConfiguration.CustomSourceConfiguration.Mapping
    };
  } else if (defaultConfiguration.CustomSourceConfiguration instanceof SeparateCustomSourceConfiguration) {
    return {
      SaleMapping: defaultConfiguration.CustomSourceConfiguration.SaleMapping,
      PurchaseMapping: defaultConfiguration.CustomSourceConfiguration.PurchaseMapping
    };
  }
  return void 0;
};
const mapCountryConfigurations = (countryConfigurations) => countryConfigurations.map((c) => ({
  Configuration: c.Configuration ? mapConfiguration(c.Configuration) : void 0,
  CountryCode: c.CountryCode,
  IsDeleted: c.IsDeleted
}));
const mapConfiguration = (defaultConfiguration) => ({
  AvailableTemplates: defaultConfiguration.AvailableTemplates,
  CustomSourceConfiguration: defaultConfiguration.CustomSourceConfiguration ? mapCustomSourceConfiguration(defaultConfiguration) : void 0,
  FormatSettings: defaultConfiguration.FormatSettings,
  SalePurchaseSeparately: defaultConfiguration.SalePurchaseSeparately,
  TemplateFileReference: defaultConfiguration.TemplateFileReference ? mapTemplateFileReference(defaultConfiguration) : void 0
});
export const mapGenerationConfiguration = (generationConfiguration) => ({
  ComplianceUploadGenerationMode: generationConfiguration.ComplianceUploadGenerationMode,
  IsCustomSource: generationConfiguration.IsCustomSource,
  CountryConfigurations: generationConfiguration.CountryConfigurations ? mapCountryConfigurations(generationConfiguration.CountryConfigurations) : void 0,
  DefaultConfiguration: generationConfiguration.DefaultConfiguration ? mapConfiguration(generationConfiguration.DefaultConfiguration) : void 0
});
