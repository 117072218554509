var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const SUPPORTED_CURRENCIES_KEY = "md_supported_currencies";
export const getSupportedCurrencies = (masterDataClient) => __async(void 0, null, function* () {
  const key = `${SUPPORTED_CURRENCIES_KEY}`;
  const storedCurrencies = sessionStorage[key];
  if (storedCurrencies) {
    return JSON.parse(storedCurrencies);
  } else {
    const currencies = (yield masterDataClient.getSupportedCurrencies()).data;
    sessionStorage.setItem(key, JSON.stringify(currencies));
    return currencies;
  }
});
