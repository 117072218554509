var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import i18next from "i18next";
import { compareDates, interpretYearOneAsUndefined } from "GlobalShared/helpers/dateHelper";
export const ReturnLibraryFieldNames = {
  Details: "Details",
  CompanyId: "CompanyId",
  CompanyName: "CompanyName",
  TaxReturnType: "TaxReturnType",
  ServiceCountry: "ServiceCountry",
  EstablishmentCountryType: "EstablishmentCountryType",
  PeriodStartDate: "PeriodStartDate",
  ClientApprovalDueDate: "ClientApprovalDueDate",
  SubmissionDueDate: "SubmissionDueDate",
  ReturnGenerationDueDate: "ReturnGenerationDueDate",
  PaymentDueDate: "PaymentDueDate",
  PaymentProofUploaded: "PaymentProofUploaded",
  Status: "Status",
  PaymentStatus: "PaymentStatus",
  Revision: "Revision",
  Actions: "Actions"
};
export const ALL_STATUSES = [
  "New",
  "DataUploaded",
  "ErrorReturnGen",
  "ReturnAvailable",
  "ApprovedByClient",
  "RefusedByClient",
  "ErrorAtTA",
  "SentToTA",
  "Submitted",
  "Rejected",
  "SubmittedByClient",
  "NotToBeSubmitted"
];
const ALL_WEB_STATUSES = ["New", "DataUploaded", "ReturnAvailable", "ApprovedByClient", "RefusedByClient", "Submitted", "SubmittedByClient", "SentToTA"];
export const getWebStatuses = (hasSubmissionOnlySubscription) => hasSubmissionOnlySubscription ? ALL_WEB_STATUSES : ALL_WEB_STATUSES.filter((s) => s !== "SentToTA");
export const ALL_ESTABLISHMENT_COUNTRY_TYPES = ["EU", "NonEU", "GB"];
export const ALL_STATUS_LABELS = [
  { label: "New", value: "New" },
  { label: "Data Uploaded", value: "DataUploaded" },
  { label: "Return Available", value: "ReturnAvailable" },
  { label: "Approved by Client", value: "ApprovedByClient" },
  { label: "Refused by Client", value: "RefusedByClient" },
  { label: "Error at TA", value: "ErrorAtTA" },
  { label: "Error at return gen", value: "ErrorReturnGen" },
  { label: "Sent to TA", value: "SentToTA" },
  { label: "Submitted", value: "Submitted" },
  { label: "Rejected", value: "Rejected" },
  { label: "Submitted by Client", value: "SubmittedByClient" },
  { label: "Not to be submitted", value: "NotToBeSubmitted" }
];
export const SUBMISSION_ONLY_STATUS_LABELS = [
  { label: "New", value: "New" },
  { label: "Return Available", value: "ReturnAvailable" },
  { label: "Error at TA", value: "ErrorAtTA" },
  { label: "Sent to TA", value: "SentToTA" },
  { label: "Submitted", value: "Submitted" },
  { label: "Rejected", value: "Rejected" },
  { label: "Not to be submitted", value: "NotToBeSubmitted" }
];
export const getStatusLabel = (status) => {
  const item = ALL_STATUS_LABELS.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const ALL_PAYMENT_LABELS = [
  { label: i18next.t("Due"), value: "Due" },
  { label: i18next.t("Paid to TA"), value: "Paid" },
  { label: i18next.t("Paid by client"), value: "PaidByClient" },
  { label: i18next.t("No payment needed"), value: "NoPaymentNeeded" }
];
export const getPaymentStatusLabel = (status) => {
  const item = ALL_PAYMENT_LABELS.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const ALL_ACTION_LABELS = {
  Approve: i18next.t("Approve"),
  Reject: i18next.t("Reject"),
  Submit: i18next.t("Submit"),
  Reopen: i18next.t("Reopen"),
  ReturnToApproved: i18next.t("Return to approved"),
  UploadPaymentProof: i18next.t("Upload payment proof"),
  PayComplianceFee: i18next.t("Confirm Payment"),
  UploadSubmissionProof: i18next.t("Upload submission proof"),
  UploadIntrastatSubmissionProofs: i18next.t("Upload In/Out submission proofs"),
  ManualUpload: i18next.t("Manual upload"),
  UploadNotToBeSubmittedProof: i18next.t("Upload not to be submitted proof")
};
export const ALL_TAX_RETURN_TYPE_LABELS = {
  PrimaryVAT: i18next.t("PrimaryVAT"),
  SecondaryVAT: i18next.t("SecondaryVAT"),
  ESL: i18next.t("ESL"),
  VLS: i18next.t("VLS"),
  IOSS: i18next.t("IOSS"),
  Intrastat: i18next.t("Intrastat"),
  OSS: i18next.t("OSS"),
  LocalListing: i18next.t("Local Listing")
};
export const ALL_SUBMISSION_STATUS_LABELS = {
  New: i18next.t("New"),
  DataUploaded: i18next.t("DataUploaded"),
  ErrorReturnGen: i18next.t("ErrorReturnGen"),
  ReturnAvailable: i18next.t("ReturnAvailable"),
  ApprovedByClient: i18next.t("ApprovedByClient"),
  RefusedByClient: i18next.t("RefusedByClient"),
  ErrorAtTA: i18next.t("ErrorAtTA"),
  SentToTA: i18next.t("SentToTA"),
  Submitted: i18next.t("Submitted"),
  Rejected: i18next.t("Rejected"),
  SubmittedByClient: i18next.t("SubmittedByClient"),
  NotToBeSubmitted: i18next.t("NotToBeSubmitted")
};
export const PAYMENT_PROOF_DS = [
  { value: "All", label: i18next.t("All") },
  { value: "Exists", label: i18next.t("Exists") },
  { value: "NotExists", label: i18next.t("Not exists") }
];
export const ALL_TAX_RETURN_TYPES = ["PrimaryVAT", "SecondaryVAT", "ESL", "VLS", "IOSS", "OSS", "Intrastat", "LocalListing"];
export const DEADLINE_CONFIG = [
  {
    taxReturnType: "IOSS",
    frequencyType: "Monthly",
    returnGeneratedDaysBeforeSubmissionDeadline: 7,
    approveDaysBeforeSubmissionDeadline: 5,
    submissionDeadlineType: "END_OF_NEXT_MONTH"
  }
];
export const mapSearchResultItemToVM = (searchResultItem) => {
  const vm = __spreadProps(__spreadValues({}, mapSearchSubmissionPeriodToVM(searchResultItem)), {
    TransactionFiles: [],
    DataVatFiles: [],
    InvalidVatNumbersReportFiles: [],
    OutOfScopeFiles: [],
    ReturnFiles: void 0,
    NotToBeSubmittedProof: void 0
  });
  return vm;
};
export const mapSubmissionPeriodFilesToVM = (submissionPeriodFiles) => {
  const vm = {
    TransactionFiles: mapSubmissionPeriodFileListToVM(submissionPeriodFiles.TransactionFiles),
    DataVatFiles: mapSubmissionPeriodFileListToVM(submissionPeriodFiles.DataVatFiles),
    ReturnFiles: mapSubmissionPeriodFileListToVM(submissionPeriodFiles.ReturnFiles),
    InvalidVatNumbersReportFiles: mapSubmissionPeriodFileListToVM(submissionPeriodFiles.InvalidVatNumbersReportFiles),
    OutOfScopeFiles: mapSubmissionPeriodFileListToVM(submissionPeriodFiles.OutOfScopeFiles),
    NotToBeSubmittedProof: submissionPeriodFiles.NotToBeSubmittedProof ? {
      CreatedAt: interpretYearOneAsUndefined(submissionPeriodFiles.NotToBeSubmittedProof.CreatedAt),
      Name: submissionPeriodFiles.NotToBeSubmittedProof.Name,
      Reference: submissionPeriodFiles.NotToBeSubmittedProof.Reference
    } : void 0
  };
  return vm;
};
export const mapSubmissionPeriodFileListToVM = (files) => {
  if (!files) {
    return [];
  }
  return files.map((f) => {
    const vm = {
      CreatedAt: interpretYearOneAsUndefined(f.CreatedAt),
      Name: f.Name,
      Reference: f.Reference,
      PeriodStartDate: f.PeriodStartDate,
      Version: f.Version
    };
    return vm;
  }).sort((a, b) => compareDates(a.PeriodStartDate, b.PeriodStartDate));
};
const mapSearchSubmissionPeriodToVM = (submissionPeriodCommon) => {
  const vm = {
    Id: submissionPeriodCommon.Id,
    DetailsOpen: false,
    ClientId: submissionPeriodCommon == null ? void 0 : submissionPeriodCommon.CompanyId,
    ClientName: submissionPeriodCommon == null ? void 0 : submissionPeriodCommon.CompanyName,
    TaxReturnType: submissionPeriodCommon.TaxReturnType,
    FrequencyType: submissionPeriodCommon.FrequencyType,
    EstablishmentCountryCode: submissionPeriodCommon.EstablishmentCountryCode,
    EstablishmentCountryType: submissionPeriodCommon == null ? void 0 : submissionPeriodCommon.EstablishmentCountryType,
    ServiceCountry: {
      Code: submissionPeriodCommon.ServiceCountryCode,
      Name: submissionPeriodCommon.ServiceCountryCode
    },
    Status: submissionPeriodCommon.Status,
    Period: submissionPeriodCommon.Period,
    PeriodStartDate: submissionPeriodCommon.PeriodStartDate,
    ReturnGenerationDueDate: interpretYearOneAsUndefined(submissionPeriodCommon.ReturnGenerationDueDate),
    ReturnGenerationDate: interpretYearOneAsUndefined(submissionPeriodCommon.ReturnGenerationDate),
    ClientApprovalDueDate: interpretYearOneAsUndefined(submissionPeriodCommon.ClientApprovalDueDate),
    ClientApprovalDate: interpretYearOneAsUndefined(submissionPeriodCommon.ClientApprovalDate),
    SubmissionDueDate: interpretYearOneAsUndefined(submissionPeriodCommon.SubmissionDueDate),
    SubmissionDate: interpretYearOneAsUndefined(submissionPeriodCommon.SubmissionDate),
    SubmittedAndPaidDueDate: interpretYearOneAsUndefined(submissionPeriodCommon.PaymentDueDate),
    SubmittedAndPaidDate: interpretYearOneAsUndefined(submissionPeriodCommon.SubmittedAndPaidDate),
    PaymentDueDate: interpretYearOneAsUndefined(submissionPeriodCommon.PaymentDueDate),
    PaymentProof: submissionPeriodCommon.PaymentProof,
    PaymentStatus: submissionPeriodCommon.PaymentStatus,
    PaymentUrl: submissionPeriodCommon.PaymentUrl,
    PaymentCategory: submissionPeriodCommon.PaymentCategory,
    Revision: submissionPeriodCommon == null ? void 0 : submissionPeriodCommon.Revision,
    EFiling: submissionPeriodCommon.EFiling,
    SubmissionProofs: submissionPeriodCommon.SubmissionProofs,
    IsSubmissionOnly: submissionPeriodCommon.IsSubmissionOnly,
    IgnoreWarnings: false
  };
  return vm;
};
export const VATPaymentDataSource = ["Nil", "Payable", "Reclaimable"];
