var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Order } from "./../models/V1/Orders/Get/Order";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResponse } from "./../models/V1/Orders/Search/SearchResponse";
export class Orders {
  /**
   * Creates an instance of Orders.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Orders
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CreateOrderRq_ _createOrderRq
   * @returns Promise<CreateOrderRq_>
   * @memberof Orders
   */
  createOrder(pCreateOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pCreateOrderRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: Order },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetOrderRq_ _getOrderRq
   * @returns Promise<GetOrderRq_>
   * @memberof Orders
   */
  getOrder(pGetOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pGetOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Order },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateOrderRq_ _updateOrderRq
   * @returns Promise<UpdateOrderRq_>
   * @memberof Orders
   */
  updateOrder(pUpdateOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pUpdateOrderRq.orderId, required: true }
    ]);
    client.addBody({ value: pUpdateOrderRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FixOrderRq_ _fixOrderRq
   * @returns Promise<FixOrderRq_>
   * @memberof Orders
   */
  fixOrder(pFixOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/fix",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pFixOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ApproveDraftOrderRq_ _approveDraftOrderRq
   * @returns Promise<ApproveDraftOrderRq_>
   * @memberof Orders
   */
  approveDraftOrder(pApproveDraftOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/ApproveDraft",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pApproveDraftOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RejectDraftOrderRq_ _rejectDraftOrderRq
   * @returns Promise<RejectDraftOrderRq_>
   * @memberof Orders
   */
  rejectDraftOrder(pRejectDraftOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/RejectDraft",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pRejectDraftOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CancelOrderRq_ _cancelOrderRq
   * @returns Promise<CancelOrderRq_>
   * @memberof Orders
   */
  cancelOrder(pCancelOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/Cancel",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pCancelOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadContractRq_ _downloadContractRq
   * @returns Promise<DownloadContractRq_>
   * @memberof Orders
   */
  downloadContract(pDownloadContractRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/contract",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pDownloadContractRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchOrdersRq_ _searchOrdersRq
   * @returns Promise<SearchOrdersRq_>
   * @memberof Orders
   */
  searchOrders(pSearchOrdersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchOrdersRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResponse },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadBillingDocumentRq_ _downloadBillingDocumentRq
   * @returns Promise<DownloadBillingDocumentRq_>
   * @memberof Orders
   */
  downloadBillingDocument(pDownloadBillingDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/documents/{documentId}",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pDownloadBillingDocumentRq.orderId, required: true },
      { name: "documentId", value: pDownloadBillingDocumentRq.documentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportOrdersRq_ _exportOrdersRq
   * @returns Promise<ExportOrdersRq_>
   * @memberof Orders
   */
  exportOrders(pExportOrdersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/Export",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv, application/json",
      options
    );
    client.addBody({ value: pExportOrdersRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveVatRq_ _removeVatRq
   * @returns Promise<RemoveVatRq_>
   * @memberof Orders
   */
  removeVat(pRemoveVatRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/summary/vat",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pRemoveVatRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
