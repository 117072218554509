var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TypeOfGoods } from "./../ComplianceMasterData/TypeOfGoods";
export class TransactionType {
  constructor(_data) {
    /**
     */
    __publicField(this, "AvailableTypeOfGoods");
    /**
     */
    __publicField(this, "Category");
    /**
     */
    __publicField(this, "Code");
    /**
     */
    __publicField(this, "Description");
    /**
     */
    __publicField(this, "IsCredit");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TransactionType
     */
    __publicField(this, "$type", "V1.TransactionTypes.TransactionType");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AvailableTypeOfGoods"] && _data["AvailableTypeOfGoods"].constructor === Array) {
        this.AvailableTypeOfGoods = [];
        for (let item of _data["AvailableTypeOfGoods"]) {
          const availableTypeOfGoods_ = new TypeOfGoods();
          this.AvailableTypeOfGoods.push(availableTypeOfGoods_.init(item));
        }
      }
      this.Category = TransactionTypeCategory[_data["Category"]];
      this.Code = _data["Code"];
      this.Description = _data["Description"];
      this.IsCredit = _data["IsCredit"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AvailableTypeOfGoods && this.AvailableTypeOfGoods.constructor === Array) {
      _data["AvailableTypeOfGoods"] = [];
      for (let item of this.AvailableTypeOfGoods) {
        _data["AvailableTypeOfGoods"].push(item.toJSON());
      }
    }
    _data["Category"] = TransactionTypeCategory[this.Category];
    _data["Code"] = this.Code;
    _data["Description"] = this.Description;
    _data["IsCredit"] = this.IsCredit;
    return _data;
  }
}
export var TransactionTypeCategory = /* @__PURE__ */ ((TransactionTypeCategory2) => {
  TransactionTypeCategory2["Sale"] = "Sale";
  TransactionTypeCategory2["Purchase"] = "Purchase";
  return TransactionTypeCategory2;
})(TransactionTypeCategory || {});
