var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FormatSettingsFactory } from "./FormatSettingsFactory";
import { UpdateGenerationConfiguration } from "./UpdateGenerationConfiguration";
export class UpdateCustomSourceConfiguration extends UpdateGenerationConfiguration {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "AvailableTemplate");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "FormatSettings");
    /**
     */
    __publicField(this, "Mode");
    /**
     */
    __publicField(this, "SalePurchaseSeparately");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdateCustomSourceConfiguration
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.UpdateCustomSourceConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.AvailableTemplate = UpdateCustomSourceConfigurationAvailableTemplate[_data["AvailableTemplate"]];
      this.CountryCode = _data["CountryCode"];
      const $typeFormatSettings = _data["FormatSettings"] && _data["FormatSettings"].$type;
      if ($typeFormatSettings) {
        const formatSettings_ = FormatSettingsFactory.create($typeFormatSettings);
        this.FormatSettings = formatSettings_.init(_data["FormatSettings"]);
      } else {
        this.FormatSettings = _data["FormatSettings"];
      }
      this.Mode = UpdateCustomSourceConfigurationMode[_data["Mode"]];
      this.SalePurchaseSeparately = _data["SalePurchaseSeparately"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["AvailableTemplate"] = UpdateCustomSourceConfigurationAvailableTemplate[this.AvailableTemplate];
    _data["CountryCode"] = this.CountryCode;
    _data["FormatSettings"] = this.FormatSettings ? this.FormatSettings.toJSON() : void 0;
    _data["Mode"] = UpdateCustomSourceConfigurationMode[this.Mode];
    _data["SalePurchaseSeparately"] = this.SalePurchaseSeparately;
    return _data;
  }
}
export var UpdateCustomSourceConfigurationAvailableTemplate = /* @__PURE__ */ ((UpdateCustomSourceConfigurationAvailableTemplate2) => {
  UpdateCustomSourceConfigurationAvailableTemplate2["Amazon"] = "Amazon";
  UpdateCustomSourceConfigurationAvailableTemplate2["Mp"] = "Mp";
  UpdateCustomSourceConfigurationAvailableTemplate2["Csv"] = "Csv";
  UpdateCustomSourceConfigurationAvailableTemplate2["Dsv"] = "Dsv";
  UpdateCustomSourceConfigurationAvailableTemplate2["Excel"] = "Excel";
  UpdateCustomSourceConfigurationAvailableTemplate2["SAPText"] = "SAPText";
  return UpdateCustomSourceConfigurationAvailableTemplate2;
})(UpdateCustomSourceConfigurationAvailableTemplate || {});
export var UpdateCustomSourceConfigurationMode = /* @__PURE__ */ ((UpdateCustomSourceConfigurationMode2) => {
  UpdateCustomSourceConfigurationMode2["PerCountry"] = "PerCountry";
  UpdateCustomSourceConfigurationMode2["AllCountryInOne"] = "AllCountryInOne";
  return UpdateCustomSourceConfigurationMode2;
})(UpdateCustomSourceConfigurationMode || {});
