var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class CustomSourceValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "FieldName");
    /**
     */
    __publicField(this, "FieldType");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "TotalCount");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CustomSourceValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceUploads.CustomSourceValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.FieldName = _data["FieldName"];
      this.FieldType = CustomSourceValidationMessageFieldType[_data["FieldType"]];
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = CustomSourceValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["FieldName"] = this.FieldName;
    _data["FieldType"] = CustomSourceValidationMessageFieldType[this.FieldType];
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = CustomSourceValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var CustomSourceValidationMessageFieldType = /* @__PURE__ */ ((CustomSourceValidationMessageFieldType2) => {
  CustomSourceValidationMessageFieldType2["Date"] = "Date";
  CustomSourceValidationMessageFieldType2["String"] = "String";
  CustomSourceValidationMessageFieldType2["Currency"] = "Currency";
  CustomSourceValidationMessageFieldType2["Number"] = "Number";
  CustomSourceValidationMessageFieldType2["CountryCode"] = "CountryCode";
  return CustomSourceValidationMessageFieldType2;
})(CustomSourceValidationMessageFieldType || {});
export var CustomSourceValidationMessageValidationCode = /* @__PURE__ */ ((CustomSourceValidationMessageValidationCode2) => {
  CustomSourceValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  CustomSourceValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  CustomSourceValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  CustomSourceValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  CustomSourceValidationMessageValidationCode2["Required"] = "Required";
  CustomSourceValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  CustomSourceValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  CustomSourceValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  CustomSourceValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  CustomSourceValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  CustomSourceValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  CustomSourceValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  CustomSourceValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  CustomSourceValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  CustomSourceValidationMessageValidationCode2["Empty"] = "Empty";
  CustomSourceValidationMessageValidationCode2["IntrastatEmpty"] = "IntrastatEmpty";
  return CustomSourceValidationMessageValidationCode2;
})(CustomSourceValidationMessageValidationCode || {});
