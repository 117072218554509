import { FieldDefaultValue } from "./FieldDefaultValue";
import { CountryFieldDefaultValue } from "./CountryFieldDefaultValue";
import { CurrencyFieldDefaultValue } from "./CurrencyFieldDefaultValue";
export class FieldDefaultValueFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.FieldDefaultValue") {
      const result = new FieldDefaultValue();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.CountryFieldDefaultValue") {
      const result = new CountryFieldDefaultValue();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.CurrencyFieldDefaultValue") {
      const result = new CurrencyFieldDefaultValue();
      return result;
    }
    throw new Error($type + " not found");
  }
}
