var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useComplianceUploads } from "GlobalShared/components/compliance-uploads/compliance-uploads";
import {
  ALL_STATUS,
  mapComplianceUpload,
  mapComplianceUploadSearch
} from "GlobalShared/components/compliance-uploads/ComplianceUploadModels";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { mapCompanyDetailToCompanyServicesModel, mapDefaultCompanyConfiguration } from "GlobalShared/mappers/ServicesMapper";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { useServiceCountries, useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import {
  Company as CompanyClient,
  ComplianceMasterData as ComplianceMasterDataClient,
  ComplianceUploads as ComplianceUploadsClient,
  DataProcessingJobs as DataProcessingJobsClient,
  GenerationConfiguration as GenerationConfigurationClient
} from "WebServices/APIs/self/clients";
import { ComplianceCompany as ComplianceCompanyClient } from "WebServices/APIs/self/clients/ComplianceCompany";
import { UpdatedCompanyConfiguration } from "WebServices/APIs/self/models/V1/ComplianceUploads/Configuration";
import { UpdatedAmazonConfiguration, UpdatedSellerEntity } from "WebServices/APIs/self/models/V1/ComplianceUploads/Configuration/Amazon";
import { Field, Filter, Pager, SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceUploads/Search";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceUploads/Search/Field";
import { currentLocale, getRouteByKey } from "../../globals";
import {
  AmazonDataProcessingJob,
  AmazonDataProcessingJobCreate,
  IossDataProcessingJob,
  IossDataProcessingJobCreate,
  AmazonSaftDataProcessingJob,
  AmazonSaftDataProcessingJobCreate,
  VatDataProcessingJob,
  VatDataProcessingJobCreate,
  CustomSaftDataProcessingJobCreate,
  CustomSaftDataProcessingJob,
  CustomSourceDataProcessingJob,
  CustomSourceDataProcessingJobCreate
} from "WebServices/APIs/self/models/V1/DataProcessingJobs";
import { SearchRequest as DataProcessingSearchRequest, Filter as DataProcessingFilter } from "WebServices/APIs/self/models/V1/DataProcessingJobs/Search";
import { HasIncompleteSku } from "WebServices/APIs/self/models/V1/ComplianceMasterData";
import { AnyTaxCodeFilter } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode";
import { mapGenerationConfiguration } from "WebComponents/custom-source/WebCustomDataTemplateMappers";
export function AdminComplianceUpload() {
  const getFilter = (showAll) => {
    const clientFilter = new Filter();
    clientFilter.Statuses = showAll ? ALL_STATUS : ALL_STATUS.filter((f) => f !== "DataUploadFinalized" && f !== "Error" && f !== "SentToReturnGeneration" && f !== "DataVatGenerated" && f !== "UploadProcessed");
    return clientFilter;
  };
  const mapValidationMessages = (messages) => {
    return messages ? messages.map((msg) => {
      const vm = {
        validationCode: msg.ValidationCode,
        columnName: msg.ColumnName,
        columnCode: msg.ColumnCode,
        parameters: msg.Parameters,
        rowNumber: msg.RowNumber,
        totalCount: msg.TotalCount,
        isWarning: msg.IsWarning,
        fieldType: msg.FieldType
      };
      return vm;
    }) : [];
  };
  const mapValidationResult = (job) => {
    if (job.ValidationResult === void 0) {
      return void 0;
    }
    const result = {
      validationMessages: mapValidationMessages(job.ValidationResult.ValidationMessages),
      numberOfTotalLinesWithError: job.ValidationResult.NumberOfTotalLinesWithError,
      isSuccess: job.ValidationResult.IsSuccess
    };
    return result;
  };
  const mapDataProcessingJob = (job) => {
    const uploadJobVM = {
      type: "None",
      id: job.Id,
      status: job.Status,
      validationResult: {},
      blobName: job.BlobName,
      complianceUploadId: job.ComplianceUploadId,
      latestUploadedFile: job.LatestUploadedFile
    };
    if (job instanceof VatDataProcessingJob) {
      uploadJobVM.type = "Vat";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof AmazonDataProcessingJob) {
      uploadJobVM.type = "Amazon";
      uploadJobVM.sellerEntityId = job.SellerEntityId;
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof IossDataProcessingJob) {
      uploadJobVM.type = "Ioss";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof AmazonSaftDataProcessingJob) {
      uploadJobVM.type = "AmazonSaft";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof CustomSaftDataProcessingJob) {
      uploadJobVM.type = "CustomSaft";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof CustomSourceDataProcessingJob && job.Type === "One") {
      uploadJobVM.type = "CustomOneFile";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof CustomSourceDataProcessingJob && job.Type === "Sale") {
      uploadJobVM.type = "CustomSaleFile";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    if (job instanceof CustomSourceDataProcessingJob && job.Type === "Purchase") {
      uploadJobVM.type = "CustomPurchaseFile";
      uploadJobVM.validationResult = mapValidationResult(job);
    }
    return uploadJobVM;
  };
  const removeUploadedVatTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedVatMpTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const removeUploadedAmazonTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedAmazonTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const finalizeComplianceUpload = (complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus) => __async(this, null, function* () {
    if (complianceUpload.Type === "IOSS") {
      yield complianceUploadClient.finalizeIossComplianceUpload({
        complianceUploadId: complianceUpload.Id
      });
    } else {
      if (complianceUpload.Status === "New") {
        yield complianceUploadClient.finalizeVatComplianceUpload({
          complianceUploadId: complianceUpload.Id
        });
      } else if (complianceUpload.Status === "SaftCompliance") {
        yield complianceUploadClient.finalizeSAFT({
          complianceUploadId: complianceUpload.Id,
          confirmNoSaft: confirmNoSaft === void 0 ? false : confirmNoSaft
        });
      } else if (complianceUpload.Status === "MasterDataSetup") {
        yield complianceUploadClient.finalizeMasterData({
          complianceUploadId: complianceUpload.Id,
          body: acknowledgeIncompleteSkus === void 0 ? false : acknowledgeIncompleteSkus
        });
      }
    }
  });
  const searchComplianceUploads = (gridState, showAll) => __async(this, null, function* () {
    const response = yield complianceUploadClient.searchComplianceUploads({
      body: new SearchRequest({
        Filter: getFilter(showAll),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new Field({
              Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    const vm = {
      uploads: response.data.ComplianceUploads.map(mapComplianceUploadSearch),
      total: response.data.Count
    };
    return vm;
  });
  const generateSaftTemplate = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceUploadClient.generateSaftTemplate({
          complianceUploadId
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const exportComplianceUploads = (_gridState, _showAll) => __async(this, null, function* () {
  });
  const getDefaultCompanyConfiguration = () => __async(this, null, function* () {
    const response = yield complianceCompanyClient.getDefaultConfiguration();
    return mapDefaultCompanyConfiguration(response.data);
  });
  const getGenerationConfiguration = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.get();
        return mapGenerationConfiguration(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getCompanyServices = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield companyClient.getCompanyDetail({
          locale: currentLocale
        });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, companyDetailResult.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateDefaultCompanyConfiguration = (companyConfiguration) => __async(this, null, function* () {
    yield complianceCompanyClient.updateDefaultConfiguration({
      body: new UpdatedCompanyConfiguration({
        AmazonConfiguration: companyConfiguration.amazonConfiguration ? new UpdatedAmazonConfiguration({
          SellerEntities: companyConfiguration.amazonConfiguration.sellerEntities.map(
            (se) => new UpdatedSellerEntity({
              Id: se.id,
              Name: se.name,
              MwsAuthToken: se.mwsAuthToken,
              IsEnabled: se.isEnabled,
              IsAutomated: se.isAutomated
            })
          ),
          UseAmazonRates: companyConfiguration.amazonConfiguration.useAmazonRates
        }) : void 0,
        UseMpTemplate: companyConfiguration.useMpTemplate,
        UseAmazonTemplate: companyConfiguration.useAmazonTemplate
      })
    });
  });
  const hasAnyIncompleteSKUs = (_companyId, complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield complianceMasterDataClient.hasAnyIncompleteSKUs({ body: new HasIncompleteSku({ ComplianceUploadId: complianceUploadId }) });
        return companyDetailResult.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const reconfirmUpload = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.checkBalance({
          complianceUploadId
        });
        const compliance = yield complianceUploadClient.getCompliance({
          complianceUploadId
        });
        return compliance.data.Status;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const cancelUpload = (_complianceUploadId) => __async(this, null, function* () {
    return void 0;
  });
  const deleteUpload = (_complianceUploadId) => __async(this, null, function* () {
    return void 0;
  });
  const resendNotification = (_complianceUploadId) => __async(this, null, function* () {
  });
  const regenerateDataVat = (_complianceUploadId) => __async(this, null, function* () {
  });
  const generateTextFile = (_complianceUploadId) => __async(this, null, function* () {
  });
  const changeIgnoreOverdue = (_complianceUploadId, _ignoreOverdue) => __async(this, null, function* () {
  });
  const createUploadJob = (complianceUploadId, fileName, type, sellerEntityId) => __async(this, null, function* () {
    const baseParams = { ComplianceUploadId: complianceUploadId, FileName: fileName };
    let body;
    switch (type) {
      case "Amazon":
        body = new AmazonDataProcessingJobCreate(__spreadProps(__spreadValues({}, baseParams), { SellerEntityId: sellerEntityId }));
        break;
      case "Vat":
        body = new VatDataProcessingJobCreate(baseParams);
        break;
      case "Ioss":
        body = new IossDataProcessingJobCreate(baseParams);
        break;
      case "AmazonSaft":
        body = new AmazonSaftDataProcessingJobCreate(baseParams);
        break;
      case "CustomSaft":
        body = new CustomSaftDataProcessingJobCreate(baseParams);
        break;
      case "CustomOneFile":
        body = new CustomSourceDataProcessingJobCreate(__spreadProps(__spreadValues({}, baseParams), { Type: "One" }));
        break;
      case "CustomSaleFile":
        body = new CustomSourceDataProcessingJobCreate(__spreadProps(__spreadValues({}, baseParams), { Type: "Sale" }));
        break;
      case "CustomPurchaseFile":
        body = new CustomSourceDataProcessingJobCreate(__spreadProps(__spreadValues({}, baseParams), { Type: "Purchase" }));
        break;
      default:
        break;
    }
    const job = yield dataProcessingJobsClient.create({
      complianceUploadId,
      body
    });
    return { correlationId: job.data.CorrelationId, jobId: job.data.JobId, sasUri: job.data.SasUri };
  });
  const finishBlobUpload = (jobId, correlationId) => __async(this, null, function* () {
    yield dataProcessingJobsClient.uploadFinished({ jobId, correlationId });
  });
  const searchUploadJobs = (complianceUploadId) => __async(this, null, function* () {
    const result = yield dataProcessingJobsClient.searchDataProcessingJobs({
      body: new DataProcessingSearchRequest({
        Filter: new DataProcessingFilter({ ComplianceUploadId: complianceUploadId })
      })
    });
    return result.data.DataProcessingJobs.map(mapDataProcessingJob);
  });
  const getUploadJob = (jobId) => __async(this, null, function* () {
    const jobResult = yield dataProcessingJobsClient.getStatus({ id: jobId });
    return mapDataProcessingJob(jobResult.data.DataProcessingJob);
  });
  const suppressUploadJobWarnings = (jobId) => __async(this, null, function* () {
    yield dataProcessingJobsClient.suppressWarnings({ id: jobId });
  });
  const deleteJob = (jobId) => __async(this, null, function* () {
    yield dataProcessingJobsClient.delete({ id: jobId });
  });
  const getCompliance = (uploadId) => __async(this, null, function* () {
    const result = yield complianceUploadClient.getCompliance({ complianceUploadId: uploadId });
    return mapComplianceUpload(result.data);
  });
  const hasAnyIncompleteTaxCodes = (countryCode) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield complianceMasterDataClient.hasAnyIncompleteTaxCode({ body: new AnyTaxCodeFilter({ CountryCode: countryCode }) });
        return companyDetailResult.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const worldCountries = useWorldCountries(currentLocale);
  const serviceCountries = useServiceCountries(currentLocale);
  const companyClient = useClient(CompanyClient);
  const complianceCompanyClient = useClient(ComplianceCompanyClient);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const complianceUploadClient = useClient(ComplianceUploadsClient);
  const dataProcessingJobsClient = useClient(DataProcessingJobsClient);
  const generationConfigurationClient = useClient(GenerationConfigurationClient);
  const complianceUploadHook = useComplianceUploads({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    serviceCountries,
    removeUploadedVatTransactions,
    removeUploadedAmazonTransactions,
    generateSaftTemplate,
    finalizeComplianceUpload,
    searchComplianceUploads,
    exportComplianceUploads,
    getDefaultCompanyConfiguration,
    getCompanyServices,
    updateDefaultCompanyConfiguration,
    hasAnyIncompleteSKUs,
    getRouteByKey,
    resendNotification,
    regenerateDataVat,
    generateTextFile,
    reconfirmUpload,
    cancelUpload,
    deleteUpload,
    changeIgnoreOverdue,
    createUploadJob,
    finishBlobUpload,
    searchUploadJobs,
    getUploadJob,
    suppressUploadJobWarnings,
    deleteJob,
    getCompliance,
    getGenerationConfiguration,
    hasAnyIncompleteTaxCodes
  });
  return complianceUploadHook.mainTemplate;
}
