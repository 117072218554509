var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { CustomDataTemplate } from "GlobalShared/components/custom-data-template/CustomDataTemplate";
import {
  CUSTOM_DATA_FIELDS_WITH_DEFAULT_VALUE_TRANSFORMATION,
  DEFAULT_COUNTRY_OPTION,
  getTypeOfDropdown
} from "GlobalShared/components/custom-data-template/CustomDataTemplateHelper";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import {
  GenerationConfiguration as GenerationConfigurationClient,
  ComplianceMasterData as ComplianceMasterDataClient,
  TransactionTypes as TransactionTypesClient
} from "WebServices/APIs/self/clients";
import {
  CustomSourceMapping as WebCustomSourceMapping,
  DefaultFormatSettings,
  DsvFormatSettings,
  UpdateCustomSourceConfiguration,
  UpdateOneCustomSourceMapping,
  UpdatePurchaseCustomSourceMapping,
  UpdateSaleCustomSourceMapping,
  FieldMapping,
  CountryFieldDefaultValue as WebCountryFieldDefaultValue,
  SubstringFieldTransformation,
  CurrencyFieldDefaultValue as WebCurrencyFieldDefaultValue,
  DeleteCustomSourceCountryConfiguration
} from "WebServices/APIs/self/models/V1/GenerationConfiguration";
import { UpdateCustomSourceConfigurationMode } from "WebServices/APIs/self/models/V1/GenerationConfiguration/UpdateCustomSourceConfiguration";
import { AddTaxCode, UpdateTaxCode } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode";
import { SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/SearchRequest";
import {
  mapTransactionType,
  mapTypeOfGoods,
  mapVatRateTypeSetting,
  TaxCodeFieldNames
} from "GlobalShared/components/custom-data-template/TaxCodeModels";
import { Filter, Pager, Field } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/Field";
import { useSupportedCurrencies, useWorldCountries, useServiceCountries } from "WebComponents/hooks/WebCustomHooks";
import i18next from "i18next";
import { currentLocale } from "../../globals";
import { mapGenerationConfiguration } from "./WebCustomDataTemplateMappers";
export function AdminCustomDataTemplate() {
  const getFilter = (searchRequest) => {
    const clientFilter = new Filter();
    if (searchRequest.gridState.appliedFilters.length > 0) {
      searchRequest.gridState.appliedFilters.forEach((filter) => {
        if (filter.filterProps.selectedValues) {
          if (filter.field === TaxCodeFieldNames.ClientTaxCode) {
            clientFilter.ClientTaxCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.Country) {
            clientFilter.CountryCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TransactionType) {
            clientFilter.TransactionTypeCodes = [...filter.filterProps.selectedValues];
          } else if (filter.field === TaxCodeFieldNames.VatRate) {
            clientFilter.VatRateTypes = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TypeOfGoods) {
            clientFilter.TypeOfGoods = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.ReverseCharge) {
            clientFilter.ReverseCharge = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.OutOfScope) {
            clientFilter.OutOfScope = filter.filterProps.selectedValues[0];
          }
        }
      });
    }
    clientFilter.OnlyMissing = searchRequest.onlyMissing ? true : void 0;
    return clientFilter;
  };
  const searchTaxCodes = (searchRequest, loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.searchTaxCodes({
          body: new SearchRequest({
            Filter: getFilter(searchRequest),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        return result.data;
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const getTypeOfGoods = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield complianceMasterDataClient.getTypeOfGoods();
        return (_a = result.data) == null ? void 0 : _a.map((t) => mapTypeOfGoods(t));
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const getVATRateTypeSettings = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield complianceMasterDataClient.getVATRateTypeSettings();
        return (_a = result.data) == null ? void 0 : _a.map((t) => mapVatRateTypeSetting(t));
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const addTaxCode = (taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new AddTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoodsId,
      VatRateType: taxCode.vatRateType,
      OutOfScope: taxCode.outOfScope
    });
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.addTaxCode({ body: bodyToPost });
        notifySuccess(i18next.t("Tax code added successfully"));
        return result.data;
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const updateTaxCode = (id, taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new UpdateTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoodsId,
      VatRateType: taxCode.vatRateType,
      OutOfScope: taxCode.outOfScope
    });
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateTaxCode({ id, body: bodyToPost });
        notifySuccess(i18next.t("Tax code updated successfully"));
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const deleteTaxCode = (id, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.deleteTaxCode({ id });
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const getTransactionTypes = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield transactionTypesClient.get();
        return (_a = result.data) == null ? void 0 : _a.map((t) => mapTransactionType(t));
      }),
      (error) => notifyErrorWithWarning(error),
      void 0,
      loader
    );
  });
  const deleteCustomSourceCountryConfiguration = (countryCode, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const body = new DeleteCustomSourceCountryConfiguration({ CountryCode: countryCode });
        const countryName = (_a = worldCountries.find((c) => c.Code === countryCode)) == null ? void 0 : _a.Name;
        yield generationConfigurationClient.update({ body });
        notifySuccess(`Custom data template successfully deleted for ${countryName}`);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const updateCustomDataTemplate = (createDataStepInfo, countryCode, isAllCountryInOneFile, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        let body;
        if (isAllCountryInOneFile || createDataStepInfo.defaultCountryTemplate && countryCode === DEFAULT_COUNTRY_OPTION) {
          body = new UpdateCustomSourceConfiguration({
            AvailableTemplate: createDataStepInfo.defaultCountryTemplate.templateType,
            Mode: countryCode === DEFAULT_COUNTRY_OPTION ? UpdateCustomSourceConfigurationMode.PerCountry : UpdateCustomSourceConfigurationMode.AllCountryInOne,
            SalePurchaseSeparately: !createDataStepInfo.defaultCountryTemplate.isSaleAndPurchaseInOneFile,
            CountryCode: void 0,
            FormatSettings: createDataStepInfo.defaultCountryTemplate.templateType === "Dsv" ? new DsvFormatSettings({
              DecimalSeparator: createDataStepInfo.defaultCountryTemplate.decimalSeparator,
              IncludesHeader: createDataStepInfo.defaultCountryTemplate.hasHeaderLine,
              Delimiter: createDataStepInfo.defaultCountryTemplate.delimitier,
              DateFormat: createDataStepInfo.defaultCountryTemplate.formatSettingsDateFormat
            }) : new DefaultFormatSettings({
              DecimalSeparator: createDataStepInfo.defaultCountryTemplate.decimalSeparator,
              IncludesHeader: createDataStepInfo.defaultCountryTemplate.hasHeaderLine,
              DateFormat: createDataStepInfo.defaultCountryTemplate.formatSettingsDateFormat
            })
          });
        } else {
          body = new UpdateCustomSourceConfiguration({
            AvailableTemplate: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).templateType,
            Mode: UpdateCustomSourceConfigurationMode.PerCountry,
            SalePurchaseSeparately: !createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).isSaleAndPurchaseInOneFile,
            CountryCode: countryCode,
            FormatSettings: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).templateType === "Dsv" ? new DsvFormatSettings({
              DecimalSeparator: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).decimalSeparator,
              IncludesHeader: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).hasHeaderLine,
              Delimiter: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).delimitier,
              DateFormat: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).formatSettingsDateFormat
            }) : new DefaultFormatSettings({
              DecimalSeparator: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).decimalSeparator,
              IncludesHeader: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).hasHeaderLine,
              DateFormat: createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === countryCode).formatSettingsDateFormat
            })
          });
        }
        yield generationConfigurationClient.update({ body });
        notifySuccess(i18next.t("Custom data template updated successfully"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const getGenerationConfiguration = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.get();
        return mapGenerationConfiguration(response.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const getMetaDataConfiguration = (loader, uploadType) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.getFieldsMetadata({ uploadType });
        return response.data;
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const updateTemplateReference = (uploadType, file, countryCode, isAllCountryInOneFile) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield generationConfigurationClient.updateTemplateReference({
          uploadType,
          file,
          countryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
        });
        notifySuccess(i18next.t("File uploaded successfully"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0
    );
  });
  const getFieldDefaultValueFromString = (dropFieldName, defaultValue) => {
    if (!defaultValue)
      return void 0;
    const dropdownType = getTypeOfDropdown(dropFieldName);
    if (!dropdownType || !defaultValue)
      return void 0;
    if (dropdownType === "currency") {
      return new WebCurrencyFieldDefaultValue({ CurrencyCode: defaultValue });
    }
    if (dropdownType === "country") {
      return new WebCountryFieldDefaultValue({ CountryCode: defaultValue });
    }
    return void 0;
  };
  const getMappingFromCustomSourcePairs = (pairs, dropFieldName) => {
    var _a, _b, _c, _d;
    const pair = pairs.get(dropFieldName);
    if (!pair || !pair.dragField && !pair.defaultValue)
      return void 0;
    const hasTransformationDefaultValueField = Array.from(CUSTOM_DATA_FIELDS_WITH_DEFAULT_VALUE_TRANSFORMATION.keys()).includes(dropFieldName) && (pair == null ? void 0 : pair.defaultValue);
    const defaultValue = getFieldDefaultValueFromString(dropFieldName, (_a = pairs.get(dropFieldName)) == null ? void 0 : _a.defaultValue);
    const fallBackField = (pair == null ? void 0 : pair.fallBackField) ? [pair == null ? void 0 : pair.fallBackField.id] : void 0;
    if (hasTransformationDefaultValueField) {
      const transformation = CUSTOM_DATA_FIELDS_WITH_DEFAULT_VALUE_TRANSFORMATION.get(dropFieldName);
      const transformationPair = pairs.get(transformation == null ? void 0 : transformation.transformationFrom);
      return new FieldMapping({
        Source: (_b = pair == null ? void 0 : pair.dragField) == null ? void 0 : _b.id,
        FallbackSources: fallBackField,
        //Devnote: There is only one transformation type for now
        //TODO: Update this if there are more transformation types, add a switch case here
        Transformation: new SubstringFieldTransformation({
          Length: 2,
          StartIndex: 0,
          Source: (_c = transformationPair == null ? void 0 : transformationPair.dragField) == null ? void 0 : _c.id,
          FallbackSources: (transformationPair == null ? void 0 : transformationPair.fallBackField) ? [transformationPair == null ? void 0 : transformationPair.fallBackField.id] : void 0
        })
      });
    }
    return new FieldMapping({
      Source: (_d = pair == null ? void 0 : pair.dragField) == null ? void 0 : _d.id,
      DefaultValue: defaultValue,
      FallbackSources: fallBackField
    });
  };
  const getWebSourceMapping = (pairs) => new WebCustomSourceMapping({
    CommodityCode: getMappingFromCustomSourcePairs(pairs, "CommodityCode"),
    CountryOfOrigin: getMappingFromCustomSourcePairs(pairs, "CountryOfOrigin"),
    Currency: getMappingFromCustomSourcePairs(pairs, "Currency"),
    CurrencyReporting: getMappingFromCustomSourcePairs(pairs, "CurrencyReporting"),
    CustomerCountry: getMappingFromCustomSourcePairs(pairs, "CustomerCountry"),
    CustomerCountryVatNumberUsed: getMappingFromCustomSourcePairs(pairs, "CustomerCountryVatNumberUsed"),
    CustomerName: getMappingFromCustomSourcePairs(pairs, "CustomerName"),
    CustomerProvinceCode: getMappingFromCustomSourcePairs(pairs, "CustomerProvinceCode"),
    CustomerVatNumberUsed: getMappingFromCustomSourcePairs(pairs, "CustomerVatNumberUsed"),
    DeliveryCondition: getMappingFromCustomSourcePairs(pairs, "DeliveryCondition"),
    DocumentIndicator: getMappingFromCustomSourcePairs(pairs, "DocumentIndicator"),
    ExchangeRate: getMappingFromCustomSourcePairs(pairs, "ExchangeRate"),
    InvoiceDate: getMappingFromCustomSourcePairs(pairs, "InvoiceDate"),
    InvoiceNumber: getMappingFromCustomSourcePairs(pairs, "InvoiceNumber"),
    ItemIdentifier: getMappingFromCustomSourcePairs(pairs, "ItemIdentifier"),
    ModeOfTransport: getMappingFromCustomSourcePairs(pairs, "ModeOfTransport"),
    ProductIdentifier: getMappingFromCustomSourcePairs(pairs, "ProductIdentifier"),
    Quantity: getMappingFromCustomSourcePairs(pairs, "Quantity"),
    ReportingCountry: getMappingFromCustomSourcePairs(pairs, "ReportingCountry"),
    SupplierCountry: getMappingFromCustomSourcePairs(pairs, "SupplierCountry"),
    SupplierCountryVatNumberUsed: getMappingFromCustomSourcePairs(pairs, "SupplierCountryVatNumberUsed"),
    SupplierName: getMappingFromCustomSourcePairs(pairs, "SupplierName"),
    SupplierProvinceCode: getMappingFromCustomSourcePairs(pairs, "SupplierProvinceCode"),
    SupplierVatNumberUsed: getMappingFromCustomSourcePairs(pairs, "SupplierVatNumberUsed"),
    TaxableBasis: getMappingFromCustomSourcePairs(pairs, "TaxableBasis"),
    TotalValueLine: getMappingFromCustomSourcePairs(pairs, "TotalValueLine"),
    TransactionDate: getMappingFromCustomSourcePairs(pairs, "TransactionDate"),
    TransactionType: getMappingFromCustomSourcePairs(pairs, "TransactionType"),
    ValueVat: getMappingFromCustomSourcePairs(pairs, "ValueVat"),
    VatCode: getMappingFromCustomSourcePairs(pairs, "VatCode"),
    Weight: getMappingFromCustomSourcePairs(pairs, "Weight")
  });
  const updateCustomSourceAllInOneMapping = (pairs, countryCode, isAllCountryInOneFile) => __async(this, null, function* () {
    yield generationConfigurationClient.updateCustomSourceMapping({
      body: new UpdateOneCustomSourceMapping({
        Mapping: getWebSourceMapping(pairs),
        CountryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
      })
    });
    notifySuccess(i18next.t("Mapping saved successfully"));
  });
  const updateCustomSourceSeparateMapping = (pairs, loader, uploadType, countryCode, isAllCountryInOneFile, callback) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        if (uploadType === "Purchase") {
          yield generationConfigurationClient.updateCustomSourceMapping({
            body: new UpdatePurchaseCustomSourceMapping({
              Mapping: getWebSourceMapping(pairs),
              CountryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
            })
          });
        } else if (uploadType === "Sale") {
          yield generationConfigurationClient.updateCustomSourceMapping({
            body: new UpdateSaleCustomSourceMapping({
              Mapping: getWebSourceMapping(pairs),
              CountryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
            })
          });
        }
        notifySuccess(i18next.t("Mapping saved successfully"));
        if (callback) {
          callback();
        }
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const getValidationResult = (uploadType, file, loader, countryCode, isAllCountryInOneFile) => {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.getValidationResult({
          uploadType,
          file,
          countryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
        });
        return mapValidationResult(response.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  };
  const getTemplateFile = (uploadType, loader, countryCode, isAllCountryInOneFile) => {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.getTemplateFile({
          uploadType,
          countryCode: isAllCountryInOneFile || countryCode === DEFAULT_COUNTRY_OPTION ? void 0 : countryCode
        });
        return response.data;
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  };
  const mapValidationResult = (validationResult) => ({
    IsSuccess: validationResult.IsSuccess,
    NumberOfTotalLinesWithError: validationResult.NumberOfTotalLinesWithError,
    ValidationMessages: validationResult.ValidationMessages.map((v) => ({
      FieldName: v.FieldName,
      FieldType: v.FieldType,
      RowNumber: v.RowNumber,
      TotalCount: v.TotalCount,
      ValidationCode: v.ValidationCode
    }))
  });
  const generationConfigurationClient = useClient(GenerationConfigurationClient);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const transactionTypesClient = useClient(TransactionTypesClient);
  const worldCountries = useWorldCountries(currentLocale);
  const serviceCountries = useServiceCountries(currentLocale);
  const availableCurrencies = useSupportedCurrencies();
  return CustomDataTemplate({
    availableCurrencies,
    worldCountries,
    serviceCountries,
    getGenerationConfiguration,
    updateCustomDataTemplate,
    getMetaDataConfiguration,
    updateTemplateReference,
    searchTaxCodes,
    getTypeOfGoods,
    getVATRateTypeSettings,
    addTaxCode,
    updateTaxCode,
    deleteTaxCode,
    getTransactionTypes,
    updateCustomSourceSeparateMapping,
    getValidationResult,
    getTemplateFile,
    updateCustomSourceAllInOneMapping,
    deleteCustomSourceCountryConfiguration
  });
}
