var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { UserData } from "./../models/V1/Users/UserData";
import { SearchResult } from "./../models/V1/Users/Search/SearchResult";
export class WebUsers {
  /**
   * Creates an instance of WebUsers.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof WebUsers
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ChangeEmailRq_ _changeEmailRq
   * @returns Promise<ChangeEmailRq_>
   * @memberof WebUsers
   */
  changeEmail(pChangeEmailRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/{userId}/email",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pChangeEmailRq.userId, required: true }
    ]);
    client.addBody({ value: pChangeEmailRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetUsersRq_ _getUsersRq
   * @returns Promise<GetUsersRq_>
   * @memberof WebUsers
   */
  getUsers(pGetUsersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pGetUsersRq.body, isArray: true, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: UserData, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param InviteUserRq_ _inviteUserRq
   * @returns Promise<InviteUserRq_>
   * @memberof WebUsers
   */
  inviteUser(pInviteUserRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/invite",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pInviteUserRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PatchCompaniesRq_ _patchCompaniesRq
   * @returns Promise<PatchCompaniesRq_>
   * @memberof WebUsers
   */
  patchCompanies(pPatchCompaniesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/{userId}/companies",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pPatchCompaniesRq.userId, required: true }
    ]);
    client.addBody({ value: pPatchCompaniesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PatchPspsRq_ _patchPspsRq
   * @returns Promise<PatchPspsRq_>
   * @memberof WebUsers
   */
  patchPsps(pPatchPspsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/{userId}/psps",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pPatchPspsRq.userId, required: true }
    ]);
    client.addBody({ value: pPatchPspsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchUsersRq_ _searchUsersRq
   * @returns Promise<SearchUsersRq_>
   * @memberof WebUsers
   */
  searchUsers(pSearchUsersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchUsersRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateUserRq_ _updateUserRq
   * @returns Promise<UpdateUserRq_>
   * @memberof WebUsers
   */
  updateUser(pUpdateUserRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/WebUsers/{userId}",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pUpdateUserRq.userId, required: true }
    ]);
    client.addBody({ value: pUpdateUserRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
