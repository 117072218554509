import { UpdateGenerationConfiguration } from "./UpdateGenerationConfiguration";
import { DeleteCustomSourceCountryConfiguration } from "./DeleteCustomSourceCountryConfiguration";
import { UpdateCustomSourceConfiguration } from "./UpdateCustomSourceConfiguration";
import { UpdateToEmptyCustomSourceConfiguration } from "./UpdateToEmptyCustomSourceConfiguration";
import { UpdateToGeneralConfiguration } from "./UpdateToGeneralConfiguration";
export class UpdateGenerationConfigurationFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.UpdateGenerationConfiguration") {
      const result = new UpdateGenerationConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.DeleteCustomSourceCountryConfiguration") {
      const result = new DeleteCustomSourceCountryConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdateCustomSourceConfiguration") {
      const result = new UpdateCustomSourceConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdateToEmptyCustomSourceConfiguration") {
      const result = new UpdateToEmptyCustomSourceConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdateToGeneralConfiguration") {
      const result = new UpdateToGeneralConfiguration();
      return result;
    }
    throw new Error($type + " not found");
  }
}
