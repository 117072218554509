var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useCreateDataTemplateStep } from "./useCreateDataTemplateStep";
import { useOneFileConfiguration } from "./useOneFileConfiguration";
import { DEFAULT_COUNTRY_OPTION } from "./CustomDataTemplateHelper";
import { useSetupTaxCodeStep } from "./useSetupTaxCodeStep";
import { useSeparatedFileConfiguration } from "./useSalePurchaseSeparately";
import { useTestStep } from "./useTestStep";
import { useCountrySelectorStep } from "./useCountrySelectorStep";
import { useCountryNameResolver } from "GlobalShared/hooks/CountryNameResolver";
const CREATE_DATA_TEMPLATE_STEPS = [i18next.t("Upload methods"), i18next.t("Create Data Template"), i18next.t("Configure Fields"), i18next.t("Setup tax codes"), i18next.t("Test")];
export function CustomDataTemplate(props) {
  const getAndSetGenerationConfiguration = (countryCode) => __async(this, null, function* () {
    const generationConfig = yield props.getGenerationConfiguration(loader);
    if (!countryCode) {
      setGenerationConfiguration(generationConfig);
    } else if (countryCode === DEFAULT_COUNTRY_OPTION) {
      setGenerationConfiguration(__spreadProps(__spreadValues({}, generationConfig), { DefaultConfiguration: generationConfig.DefaultConfiguration }));
    } else {
      const countryConfig = generationConfig.CountryConfigurations.find((c) => c.CountryCode === countryCode);
      setGenerationConfiguration(__spreadProps(__spreadValues({}, generationConfig), { CountryConfigurations: generationConfiguration.CountryConfigurations.map((c) => c.CountryCode === countryCode ? countryConfig : c) }));
    }
  });
  const [loading, dispatchLoading] = useLoadingReducer();
  const [generationConfiguration, setGenerationConfiguration] = useState(void 0);
  const [currentConfiguration, setCurrentConfiguration] = useState(void 0);
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const [selectedCountryTab, setSelectedCountryTab] = useQueryState("country", DEFAULT_COUNTRY_OPTION);
  const { countryNameResolver } = useCountryNameResolver({ countries: props.worldCountries.map((c) => ({ name: c.Name, code: c.Code })) });
  const countrySelectorStep = useCountrySelectorStep({
    serviceCountries: props.serviceCountries,
    loader,
    generationConfiguration,
    setStep: setCurrentStep,
    setSelectedCountry: setSelectedCountryTab,
    deleteCustomSourceCountryConfiguration: props.deleteCustomSourceCountryConfiguration,
    updateCustomDataTemplate: props.updateCustomDataTemplate,
    getAndSetGenerationConfiguration
  });
  const createDataTemplateStep = useCreateDataTemplateStep({
    generationConfiguration,
    loader,
    selectedCountryTab,
    setStep: setCurrentStep,
    getAndSetGenerationConfiguration,
    updateCustomDataTemplate: props.updateCustomDataTemplate
  });
  const oneFileConfigurationStep = useOneFileConfiguration({
    worldCountries: props.worldCountries,
    loader,
    currentStep,
    availableCurrencies: props.availableCurrencies,
    selectedCountryTab,
    generationConfiguration,
    currentConfiguration,
    setStep: setCurrentStep,
    getAndSetGenerationConfiguration,
    getMetaDataConfiguration: props.getMetaDataConfiguration,
    updateTemplateReference: props.updateTemplateReference,
    getTemplateFile: props.getTemplateFile,
    updateCustomSourceAllInOneMapping: props.updateCustomSourceAllInOneMapping
  });
  const separatedFileConfigurationStep = useSeparatedFileConfiguration({
    worldCountries: props.worldCountries,
    availableCurrencies: props.availableCurrencies,
    loader,
    currentStep,
    selectedCountryTab,
    generationConfiguration,
    currentConfiguration,
    setStep: setCurrentStep,
    getMetaDataConfiguration: props.getMetaDataConfiguration,
    updateTemplateReference: props.updateTemplateReference,
    updateCustomSourceSeparateMapping: props.updateCustomSourceSeparateMapping,
    getAndSetGenerationConfiguration,
    getTemplateFile: props.getTemplateFile
  });
  const setupTaxCodeStep = useSetupTaxCodeStep({
    worldCountries: props.worldCountries,
    setStep: setCurrentStep,
    searchTaxCodes: props.searchTaxCodes,
    getTypeOfGoods: props.getTypeOfGoods,
    getVATRateTypeSettings: props.getVATRateTypeSettings,
    addTaxCode: props.addTaxCode,
    updateTaxCode: props.updateTaxCode,
    deleteTaxCode: props.deleteTaxCode,
    getTransactionTypes: props.getTransactionTypes
  });
  const testStep = useTestStep({
    generationConfiguration,
    loader,
    selectedCountryTab,
    currentConfiguration,
    getValidationResult: props.getValidationResult,
    setStep: setCurrentStep
  });
  useEffect(() => {
    getAndSetGenerationConfiguration();
  }, [oneFileConfigurationStep.uploadedFile, separatedFileConfigurationStep.uploadedFile]);
  useEffect(() => {
    if (generationConfiguration) {
      if (!selectedCountryTab || selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
        setCurrentConfiguration(generationConfiguration.DefaultConfiguration);
      } else {
        setCurrentConfiguration(generationConfiguration.CountryConfigurations.find((c) => c.CountryCode === selectedCountryTab).Configuration);
      }
    }
  }, [generationConfiguration, selectedCountryTab, currentStep]);
  const countryTabsTemplate = (tabTemplate) => {
    var _a, _b;
    return html`
		<div class="mt-8 ml-4">
			<dc-tabs
				.customClass=${"lg:text-xl"}
				.data=${[
      { name: DEFAULT_COUNTRY_OPTION, label: DEFAULT_COUNTRY_OPTION, template: html` <div class="mt-6">${tabTemplate}</div> ` },
      ...((_b = (_a = generationConfiguration == null ? void 0 : generationConfiguration.CountryConfigurations) == null ? void 0 : _a.filter((c) => !c.IsDeleted)) != null ? _b : []).sort((a, b) => countryNameResolver.resolve(a.CountryCode).localeCompare(countryNameResolver.resolve(b.CountryCode))).map((country) => ({
        name: country.CountryCode,
        label: countryNameResolver.resolve(country.CountryCode),
        template: html` <div class="mt-6">${tabTemplate}</div> `
      }))
    ]}
				.selectedTab=${selectedCountryTab}
				@tabSelected=${(e) => setSelectedCountryTab(e.detail.selectedTab)}
			></dc-tabs>
		</div>
	`;
  };
  const createDataTemplateStepTemplate = () => (generationConfiguration == null ? void 0 : generationConfiguration.ComplianceUploadGenerationMode) === "AllCountryInOne" ? createDataTemplateStep.template() : countryTabsTemplate(createDataTemplateStep.template());
  const oneFileConfigurationStepTemplate = () => (generationConfiguration == null ? void 0 : generationConfiguration.ComplianceUploadGenerationMode) === "AllCountryInOne" ? oneFileConfigurationStep.template() : countryTabsTemplate(oneFileConfigurationStep.template());
  const separatedFileConfigurationStepTemplate = () => (generationConfiguration == null ? void 0 : generationConfiguration.ComplianceUploadGenerationMode) === "AllCountryInOne" ? separatedFileConfigurationStep.template() : countryTabsTemplate(separatedFileConfigurationStep.template());
  const templateMappingStepTemplate = () => {
    var _a;
    let isSalePurchaseSeparately;
    if (selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      isSalePurchaseSeparately = generationConfiguration == null ? void 0 : generationConfiguration.DefaultConfiguration.SalePurchaseSeparately;
    } else {
      isSalePurchaseSeparately = (_a = generationConfiguration == null ? void 0 : generationConfiguration.CountryConfigurations.find((c) => c.CountryCode === selectedCountryTab)) == null ? void 0 : _a.Configuration.SalePurchaseSeparately;
    }
    return isSalePurchaseSeparately ? separatedFileConfigurationStepTemplate() : oneFileConfigurationStepTemplate();
  };
  const testStepTemplate = () => (generationConfiguration == null ? void 0 : generationConfiguration.ComplianceUploadGenerationMode) === "AllCountryInOne" ? testStep.template() : countryTabsTemplate(testStep.template());
  return html`
		<div class="mt-8 flex flex-col">
			<dc-step-progressbar
				.items=${CREATE_DATA_TEMPLATE_STEPS}
				.activeIndex=${currentStep}
				@click=${(e) => {
    if (e.detail.step) {
      setCurrentStep(e.detail.step);
    }
  }}
			></dc-step-progressbar>
			${currentStep === 0 ? countrySelectorStep.template() : ""} ${currentStep === 1 ? createDataTemplateStepTemplate() : ""} ${currentStep === 2 ? templateMappingStepTemplate() : ""}
			${currentStep === 3 ? setupTaxCodeStep.template() : ""} ${currentStep === 4 ? testStepTemplate() : ""}
		</div>
		${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
	`;
}
