var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { CommodityCodes } from "./../models/V1/ComplianceMasterData/CommodityCodes";
import { GTUCode } from "./../models/V1/ComplianceMasterData/GTUCode";
export class AmazonMasterData {
  /**
   * Creates an instance of AmazonMasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof AmazonMasterData
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ExportCommodityCodesRq_ _exportCommodityCodesRq
   * @returns Promise<ExportCommodityCodesRq_>
   * @memberof AmazonMasterData
   */
  exportCommodityCodes(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AmazonMasterData/ExportCommodityCodes",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportCurrenciesRq_ _exportCurrenciesRq
   * @returns Promise<ExportCurrenciesRq_>
   * @memberof AmazonMasterData
   */
  exportCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AmazonMasterData/ExportCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCommodityCodesRq_ _getCommodityCodesRq
   * @returns Promise<GetCommodityCodesRq_>
   * @memberof AmazonMasterData
   */
  getCommodityCodes(pGetCommodityCodesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AmazonMasterData/CommodityCodes/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pGetCommodityCodesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CommodityCodes, isArray: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetGTUCodesRq_ _getGTUCodesRq
   * @returns Promise<GetGTUCodesRq_>
   * @memberof AmazonMasterData
   */
  getGTUCodes(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AmazonMasterData/GTUCodes",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: GTUCode, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
