var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import {
  DATE_FORMAT_OPTIONS,
  DECIMAL_SYMBOL_TYPE_OPTIONS,
  DEFAULT_COUNTRY_OPTION,
  DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT,
  DELIMITER_OPTIONS,
  TEMPLATE_FORMAT_OPTIONS
} from "./CustomDataTemplateHelper";
import classNames from "classnames";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export function useCreateDataTemplateStep(props) {
  const [createDataStepInfo, setCreateDataStepInfo] = useState(void 0);
  useEffect(() => {
    var _a, _b, _c, _d, _e, _f;
    if (props.generationConfiguration) {
      const defaultConfig = (_a = props.generationConfiguration) == null ? void 0 : _a.DefaultConfiguration;
      if (((_b = props.generationConfiguration) == null ? void 0 : _b.ComplianceUploadGenerationMode) === "AllCountryInOne" && defaultConfig) {
        setCreateDataStepInfo({ companyId: (_c = props.generationConfiguration) == null ? void 0 : _c.CompanyId, defaultCountryTemplate: getDefaultDataObject(defaultConfig) });
      } else if (((_d = props.generationConfiguration) == null ? void 0 : _d.CountryConfigurations.length) > 0 || defaultConfig) {
        const countryTemplateObjects = (_e = props.generationConfiguration) == null ? void 0 : _e.CountryConfigurations.filter((c) => !c.IsDeleted).map((country) => getCountryDataObject(country.CountryCode));
        setCreateDataStepInfo({ companyId: (_f = props.generationConfiguration) == null ? void 0 : _f.CompanyId, countryTemplates: countryTemplateObjects, defaultCountryTemplate: getDefaultDataObject(defaultConfig) });
      }
    }
  }, [props.generationConfiguration]);
  const getDefaultDataObject = (defaultConfig) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    const defaultDataStepObject = {
      templateType: (_b = (_a = defaultConfig.AvailableTemplates) == null ? void 0 : _a.at(0)) != null ? _b : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.templateType,
      delimitier: (_d = (_c = defaultConfig.FormatSettings) == null ? void 0 : _c.Delimiter) != null ? _d : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.delimitier,
      decimalSeparator: (_f = (_e = defaultConfig.FormatSettings) == null ? void 0 : _e.DecimalSeparator) != null ? _f : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.decimalSeparator,
      hasHeaderLine: (_h = (_g = defaultConfig.FormatSettings) == null ? void 0 : _g.IncludesHeader) != null ? _h : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.hasHeaderLine,
      isSaleAndPurchaseInOneFile: !defaultConfig.SalePurchaseSeparately,
      formatSettingsDateFormat: (_j = (_i = defaultConfig.FormatSettings) == null ? void 0 : _i.DateFormat) != null ? _j : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.formatSettingsDateFormat,
      countryCode: void 0
    };
    return defaultDataStepObject;
  };
  const getCountryDataObject = (countryCode) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
    const countryConfig = (_a = props.generationConfiguration) == null ? void 0 : _a.CountryConfigurations.find((config) => config.CountryCode === countryCode);
    const createDataStepObject = {
      templateType: (_d = (_c = (_b = countryConfig == null ? void 0 : countryConfig.Configuration) == null ? void 0 : _b.AvailableTemplates) == null ? void 0 : _c.at(0)) != null ? _d : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.templateType,
      delimitier: (_g = (_f = (_e = countryConfig == null ? void 0 : countryConfig.Configuration) == null ? void 0 : _e.FormatSettings) == null ? void 0 : _f.Delimiter) != null ? _g : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.delimitier,
      decimalSeparator: (_j = (_i = (_h = countryConfig == null ? void 0 : countryConfig.Configuration) == null ? void 0 : _h.FormatSettings) == null ? void 0 : _i.DecimalSeparator) != null ? _j : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.decimalSeparator,
      hasHeaderLine: (_l = (_k = countryConfig == null ? void 0 : countryConfig.Configuration.FormatSettings) == null ? void 0 : _k.IncludesHeader) != null ? _l : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.hasHeaderLine,
      isSaleAndPurchaseInOneFile: !((_m = countryConfig == null ? void 0 : countryConfig.Configuration) == null ? void 0 : _m.SalePurchaseSeparately),
      formatSettingsDateFormat: (_p = (_o = (_n = countryConfig == null ? void 0 : countryConfig.Configuration) == null ? void 0 : _n.FormatSettings) == null ? void 0 : _o.DateFormat) != null ? _p : DEFAULT_CREATE_DATA_TEMPLATE_COUNTRY_OBJECT.formatSettingsDateFormat,
      countryCode
    };
    return createDataStepObject;
  };
  const onFormatOfTheTemplateInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { templateType: e.detail.value })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map(
        (ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { templateType: e.detail.value }) : ct
      )
    }));
  };
  const onDelimiterTypeInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { delimitier: e.detail.value })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map((ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { delimitier: e.detail.value }) : ct)
    }));
  };
  const onNumberDecimalSymbolInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { decimalSeparator: e.detail.value })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map(
        (ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { decimalSeparator: e.detail.value }) : ct
      )
    }));
  };
  const onHeaderLineInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { hasHeaderLine: e.detail.value })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map((ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { hasHeaderLine: e.detail.value }) : ct)
    }));
  };
  const onDateFormatInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { formatSettingsDateFormat: e.detail.selectedValue })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map(
        (ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { formatSettingsDateFormat: e.detail.selectedValue }) : ct
      )
    }));
  };
  const onSalePurchaseSeparatelyInputChange = (e) => {
    var _a;
    if (((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION) {
      setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
        defaultCountryTemplate: __spreadProps(__spreadValues({}, createDataStepInfo.defaultCountryTemplate), { isSaleAndPurchaseInOneFile: e.detail.value })
      }));
      return;
    }
    setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), {
      countryTemplates: createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.map((ct) => ct.countryCode === props.selectedCountryTab ? __spreadProps(__spreadValues({}, ct), { isSaleAndPurchaseInOneFile: e.detail.value }) : ct)
    }));
  };
  const getFormatOfTheTemmplateSelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.templateType : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.templateType;
  };
  const getDelimiterTypeSelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.delimitier : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.delimitier;
  };
  const getNumberDecimalSymbolSelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.decimalSeparator : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.decimalSeparator;
  };
  const getHeaderLineSelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.hasHeaderLine : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.hasHeaderLine;
  };
  const getDateFormatSelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.formatSettingsDateFormat : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.formatSettingsDateFormat;
  };
  const getSalePurchaseSeparatelySelectedItem = () => {
    var _a, _b;
    return ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.isSaleAndPurchaseInOneFile : (_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.isSaleAndPurchaseInOneFile;
  };
  const formatOfTheTemplateInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("What is the format of the template?")}
			.customLabelClass=${"block"}
			.dataSource=${TEMPLATE_FORMAT_OPTIONS}
			.selectedItem=${getFormatOfTheTemmplateSelectedItem()}
			@change=${onFormatOfTheTemplateInputChange}
		></dc-multiswitch>
	`;
  const delimiterTypeInputTemplate = () => {
    var _a, _b;
    return html`
		<dc-multiswitch
			class=${classNames({
      hidden: ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" || props.selectedCountryTab === DEFAULT_COUNTRY_OPTION ? (createDataStepInfo == null ? void 0 : createDataStepInfo.defaultCountryTemplate.templateType) !== "Dsv" : ((_b = createDataStepInfo == null ? void 0 : createDataStepInfo.countryTemplates.find((ct) => ct.countryCode === props.selectedCountryTab)) == null ? void 0 : _b.templateType) !== "Dsv"
    })}
			.label=${i18next.t("Select the delimiter type")}
			.customLabelClass=${"block"}
			.dataSource=${DELIMITER_OPTIONS}
			.selectedItem=${getDelimiterTypeSelectedItem()}
			@change=${onDelimiterTypeInputChange}
		></dc-multiswitch>
	`;
  };
  const numberDecimalSymbolInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Number decimal symbol")}
			.customLabelClass=${"block"}
			.dataSource=${DECIMAL_SYMBOL_TYPE_OPTIONS}
			.selectedItem=${getNumberDecimalSymbolSelectedItem()}
			@change=${onNumberDecimalSymbolInputChange}
		></dc-multiswitch>
	`;
  const headerLineInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Does the template include header line?")}
			.customLabelClass=${"block"}
			.dataSource=${[
    { label: "Yes", value: true },
    { label: "No", value: false }
  ]}
			.selectedItem=${getHeaderLineSelectedItem()}
			@change=${onHeaderLineInputChange}
		></dc-multiswitch>
	`;
  const dateFormatInputTemplate = () => html`
		<dc-select
			class="max-w-[400px]"
			.label=${i18next.t("What will be the date format used in the source files?")}
			.customLabelClass=${"block mb-2"}
			.dataSource=${DATE_FORMAT_OPTIONS.map((o) => ({ label: o.label, value: o.value }))}
			.selectedValues=${getDateFormatSelectedItem()}
			@change=${onDateFormatInputChange}
		></dc-select>
	`;
  const salePurchaseSeparatelyInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Will you provide Sale and Purchase transactions in separate files or in one file?")}
			.customLabelClass=${"block"}
			.dataSource=${[
    { label: "Separate file", value: false },
    { label: "One file", value: true }
  ]}
			.selectedItem=${getSalePurchaseSeparatelySelectedItem()}
			@change=${onSalePurchaseSeparatelyInputChange}
		></dc-multiswitch>
	`;
  const technicalInformationTemplate = () => html`
		<div class="text-xl font-semibold">${i18next.t("Technical information")}</div>
		<div class="flex gap-4 flex-wrap">${formatOfTheTemplateInputTemplate()} ${delimiterTypeInputTemplate()}</div>
		${numberDecimalSymbolInputTemplate()} ${headerLineInputTemplate()} ${dateFormatInputTemplate()}
	`;
  const uploadMethodsTemplate = () => html`
		<div class="text-xl font-semibold">${i18next.t("Upload methods")}</div>
		${salePurchaseSeparatelyInputTemplate()}
	`;
  const buttonsTemplate = () => html`
		<div class="flex items-center flex-wrap gap-4">
			${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(0) })}
			${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: () => props.setStep(2)
  })}
		</div>
	`;
  const saveCurrentCountryConfigTemplate = () => {
    var _a;
    return html`
		${PrimaryButton({
      class: "w-64 my-6",
      text: ((_a = props.generationConfiguration) == null ? void 0 : _a.ComplianceUploadGenerationMode) === "AllCountryInOne" ? i18next.t("Save configuration") : i18next.t("Save country configuration"),
      onClick: () => __async(this, null, function* () {
        var _a2, _b, _c;
        if (props.generationConfiguration) {
          yield props.updateCustomDataTemplate(
            createDataStepInfo,
            ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.ComplianceUploadGenerationMode) === "AllCountryInOne" ? void 0 : props.selectedCountryTab,
            ((_b = props.generationConfiguration) == null ? void 0 : _b.ComplianceUploadGenerationMode) === "AllCountryInOne",
            props.loader
          );
          yield props.getAndSetGenerationConfiguration(((_c = props.generationConfiguration) == null ? void 0 : _c.ComplianceUploadGenerationMode) === "AllCountryInOne" ? void 0 : props.selectedCountryTab);
        }
      })
    })}
	`;
  };
  const template = () => html`
			<div class="m-8 flex flex-col gap-4">
				${templateTitle(i18next.t("Create Data Template"))} ${technicalInformationTemplate()} ${uploadMethodsTemplate()} ${saveCurrentCountryConfigTemplate()} ${buttonsTemplate()}
			</div>
		`;
  return { template };
}
