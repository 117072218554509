var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { closeIcon, fileIcon, templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import classNames from "classnames";
import { notifyErrorWithWarning, notifyWarning } from "GlobalShared/helpers/errorHelper";
import { CUSTOM_DATA_FIELDS_WITH_DEFAULT_VALUE, FormatTypeMapping, getDefaultValueDropdownOptions, getFieldDefaultValueAsString, TAX_DESK_DATA_LABELS } from "./CustomDataTemplateHelper";
import { useSharedCustomDataTemplate } from "./useSharedCustomDataTemplates";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
export function useOneFileConfiguration(props) {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [fieldMetadata, setFieldMetadata] = useState(void 0);
  const [dragFields, setDragFields] = useState(void 0);
  const [isValidated, setIsValidated] = useState(false);
  const [pairs, setPairs] = useState(/* @__PURE__ */ new Map());
  const sharedCustomDataTemplate = useSharedCustomDataTemplate();
  useEffect(() => __async(this, null, function* () {
    const metaData = yield props.getMetaDataConfiguration(props.loader, "AllInOne");
    setFieldMetadata(metaData);
  }), [props.currentConfiguration]);
  useEffect(() => {
    var _a, _b, _c, _d;
    if ((_b = (_a = props.currentConfiguration) == null ? void 0 : _a.TemplateFileReference) == null ? void 0 : _b.TransactionDataFields) {
      const fields = (_d = (_c = props.currentConfiguration) == null ? void 0 : _c.TemplateFileReference) == null ? void 0 : _d.TransactionDataFields;
      setDragFields(
        fields.map((field) => ({
          id: field.Index,
          label: field.Name,
          info: field.Name,
          FieldType: "String",
          type: "TemplateDataSource"
        }))
      );
      setIsValidated(false);
    } else {
      setDragFields(void 0);
    }
  }, [props.currentConfiguration, fieldMetadata]);
  useEffect(() => {
    var _a, _b, _c, _d;
    if (((_a = props.currentConfiguration) == null ? void 0 : _a.CustomSourceConfiguration) && dragFields && fieldMetadata) {
      const mappings = getMappedPairs((_c = (_b = props.currentConfiguration) == null ? void 0 : _b.CustomSourceConfiguration) == null ? void 0 : _c.OneMapping);
      setPairs(mappings);
    } else if (fieldMetadata && !((_d = props.currentConfiguration) == null ? void 0 : _d.CustomSourceConfiguration)) {
      setPairsToDefault();
    }
  }, [props.currentConfiguration, dragFields, fieldMetadata]);
  const isFieldRequired = (fieldName) => {
    var _a;
    return (_a = fieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _a.IsRequired;
  };
  const isEveryRequiredDropFieldSet = () => !Array.from(pairs.keys()).some((key) => {
    var _a, _b;
    return isFieldRequired(key) && !((_a = pairs.get(key)) == null ? void 0 : _a.dragField) && !((_b = pairs.get(key)) == null ? void 0 : _b.defaultValue);
  });
  const handleNextButtonClick = () => __async(this, null, function* () {
    if (isEveryRequiredDropFieldSet()) {
      try {
        props.loader.start();
        yield props.updateCustomSourceAllInOneMapping(pairs, props.selectedCountryTab, props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne");
        yield props.getAndSetGenerationConfiguration(props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne" ? void 0 : props.selectedCountryTab);
        props.setStep(3);
      } catch (error) {
        notifyErrorWithWarning(error);
      } finally {
        props.loader.stop();
      }
    }
    setIsValidated(true);
  });
  const handleUploadTransactionDataButtonClick = (e) => __async(this, null, function* () {
    setIsFileUploading(true);
    yield props.updateTemplateReference("AllInOne", e.detail.files[0], props.selectedCountryTab, props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne");
    yield props.getAndSetGenerationConfiguration(props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne" ? void 0 : props.selectedCountryTab);
    setUploadedFile(e.detail.files[0]);
    setIsFileUploading(false);
  });
  const getMappedPairs = (mapping) => {
    var _a;
    if (!mapping)
      return new Map(fieldMetadata.map((field) => [field.Name, void 0]));
    const newPairs = /* @__PURE__ */ new Map();
    const dropfieldNames = fieldMetadata.map((field) => field.Name);
    for (const key in mapping) {
      const dropFieldName = key;
      if (!dropfieldNames.includes(dropFieldName))
        continue;
      const field = mapping[dropFieldName];
      const dragField = dragFields == null ? void 0 : dragFields.find((f) => f.id === (field == null ? void 0 : field.Source));
      const fallBackField = ((_a = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a.length) > 0 ? dragFields == null ? void 0 : dragFields.find((f) => {
        var _a2;
        return f.id === ((_a2 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a2.at(0));
      }) : void 0;
      newPairs.set(dropFieldName, { dragField, fallBackField, defaultValue: getFieldDefaultValueAsString(field == null ? void 0 : field.DefaultValue, field == null ? void 0 : field.Transformation, dropFieldName) });
    }
    return newPairs;
  };
  const setPairsToDefault = () => setPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.map((field) => [field.Name, void 0])));
  const fallBackFieldTemplate = (dropFieldName) => {
    var _a;
    return ((_a = pairs == null ? void 0 : pairs.get(dropFieldName)) == null ? void 0 : _a.fallBackField) ? fallBackPairTemplate(dropFieldName) : emptyFallBackFieldTemplate(dropFieldName);
  };
  const emptyFallBackFieldTemplate = (dropFieldName) => html`
		<dc-dropfield
			.class=${"w-36 px-4 py-2 text-center justify-center border-2 rounded-xl border-solid px-4 py-2 hover:border-dashed hover:border-gray-500 bg-white"}
			.emptyText=${`+ ${i18next.t("Add Fallback")}`}
			.allowedDragTypes=${["TemplateDataSource"]}
			.onDrop=${(e) => {
    var _a, _b;
    const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
    if (draggedItem.id === ((_b = (_a = pairs.get(dropFieldName)) == null ? void 0 : _a.dragField) == null ? void 0 : _b.id)) {
      notifyWarning(i18next.t("You cannot set the same field as fallback."));
      return;
    }
    const newPairs = new Map(pairs);
    newPairs.set(dropFieldName, __spreadProps(__spreadValues({}, pairs.get(dropFieldName)), { fallBackField: draggedItem }));
    setPairs(newPairs);
  }}
		></dc-dropfield>
	`;
  const fallBackPairTemplate = (dropFieldName) => html`
		<div class="w-36 flex gap-4 items-center">
			<div
				class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
				title=${pairs.get(dropFieldName).fallBackField.label}
			>
				<div class="truncate">${pairs.get(dropFieldName).fallBackField.label}</div>
				<span
					class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
					@click=${() => {
    const newPairs = new Map(pairs);
    newPairs.set(dropFieldName, __spreadProps(__spreadValues({}, pairs.get(dropFieldName)), { fallBackField: void 0 }));
    setPairs(newPairs);
  }}
				>
					${closeIcon("fill-black w-3 h-3")}
				</span>
			</div>
		</div>
	`;
  const defaultValueDropdownTemplate = (dropFieldName) => {
    var _a;
    return html`
			<div class="w-60">
				<dc-select
					.label=${"Default value"}
					.dataSource=${getDefaultValueDropdownOptions(dropFieldName, props.availableCurrencies, props.worldCountries)}
					.filterable=${true}
					.debounceMs=${150}
					.placeholder=${"Please select..."}
					.selectedValues=${(_a = pairs.get(dropFieldName)) == null ? void 0 : _a.defaultValue}
					@change=${(e) => {
      const newPairs = new Map(pairs);
      newPairs.set(dropFieldName, __spreadProps(__spreadValues({}, pairs.get(dropFieldName)), { defaultValue: e.detail.selectedValue }));
      setPairs(newPairs);
    }}
				></dc-select>
			</div>
		`;
  };
  const dropfieldTemplate = (dropFieldName, type) => {
    var _a, _b, _c, _d, _e, _f;
    return html`
			<div class=${classNames("flex items-center border shadow-lg rounded-xl px-6 py-4 gap-4")}>
				<div class="flex flex-col gap-1 basis-2/5">
					<span class="font-semibold">${TAX_DESK_DATA_LABELS[dropFieldName]}${isFieldRequired(dropFieldName) ? "*" : ""}</span><span>${type}</span>
				</div>
				<div class=${classNames("flex flex-col gap-4 basis-3/5", { "items-start": (_a = pairs.get(dropFieldName)) == null ? void 0 : _a.dragField, "items-end": !((_b = pairs.get(dropFieldName)) == null ? void 0 : _b.dragField) })}>
					${((_c = pairs.get(dropFieldName)) == null ? void 0 : _c.dragField) ? html`
								<div class="flex gap-4 items-center">
									<div
										class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
										title=${pairs.get(dropFieldName).dragField.label}
									>
										<div class="truncate">${pairs.get(dropFieldName).dragField.label}</div>
										<span
											class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
											@click=${() => {
      const newPairs = new Map(pairs);
      newPairs.set(dropFieldName, __spreadProps(__spreadValues({}, pairs.get(dropFieldName)), { dragField: void 0, fallBackField: void 0 }));
      setPairs(newPairs);
    }}
										>
											${closeIcon("fill-black w-3 h-3")}
										</span>
									</div>
									${fallBackFieldTemplate(dropFieldName)}
								</div>
						  ` : html`
								<div class="flex justify-end gap-4 items-center">
									<dc-dropfield
										.class=${classNames("px-4 w-60 py-2 text-center justify-center border-2 rounded-xl border-solid hover:border-dashed", {
      "border-red-500 bg-red-100": isValidated && !((_d = pairs.get(dropFieldName)) == null ? void 0 : _d.dragField) && !((_e = pairs.get(dropFieldName)) == null ? void 0 : _e.defaultValue) && isFieldRequired(dropFieldName),
      "hover:border-gray-500 bg-gray-200": !isValidated || ((_f = pairs.get(dropFieldName)) == null ? void 0 : _f.dragField) || !isFieldRequired(dropFieldName)
    })}
										.emptyText=${i18next.t("Drop data mapping here")}
										.allowedDragTypes=${["TemplateDataSource"]}
										.onDrop=${(e) => {
      const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
      const newPairs = new Map(pairs);
      newPairs.set(dropFieldName, __spreadProps(__spreadValues({}, pairs.get(dropFieldName)), { dragField: draggedItem }));
      setPairs(newPairs);
    }}
									></dc-dropfield>
								</div>
						  `}
					${CUSTOM_DATA_FIELDS_WITH_DEFAULT_VALUE.has(dropFieldName) ? defaultValueDropdownTemplate(dropFieldName) : ""}
				</div>
			</div>
		`;
  };
  const uploadTransactionDataButtonTemplate = () => {
    var _a;
    return html`
		<dc-fileupload2
			class="self-start w-fit"
			.label=${i18next.t("Upload Sample Data File")}
			.withLoader=${isFileUploading}
			.loaderPosition=${"right"}
			.showFileLink=${false}
			.accept=${FormatTypeMapping.get((_a = props.currentConfiguration) == null ? void 0 : _a.AvailableTemplates[0])}
			@upload=${(e) => __async(this, null, function* () {
      return handleUploadTransactionDataButtonClick(e);
    })}
		></dc-fileupload2>
	`;
  };
  const taxDeskDataTemplate = () => html`
		<div class="flex flex-col gap-2 w-full max-w-[750px]">
			<div class="text-xl font-bold">${i18next.t("To be mapped fields")}</div>
			<div>${i18next.t("Fields with * are mandatory to map, the rest of the fields are optional.")}</div>
			<div class="flex flex-col gap-4 w-full">
				${Array.from(pairs.keys()).map((dropFieldName) => {
    var _a;
    return dropfieldTemplate(dropFieldName, (_a = fieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _a.Type);
  })}
			</div>
		</div>
	`;
  const uploadedDataTemplate = () => (dragFields == null ? void 0 : dragFields.length) > 0 ? html`
					<div class="sticky top-20 flex flex-col gap-4 max-h-[600px] w-[340px] shadow-xl border rounded-l-xl px-8 py-6 overflow-y-auto font-semibold">
						${dragFields.map((field) => sharedCustomDataTemplate.draggableItemTemplate(field.label, field.info, field.id))}
					</div>
			  ` : "";
  const uploadSectionTemplate = () => {
    var _a, _b, _c, _d, _e;
    return html`
		<div class="flex flex-col gap-2">
			<div class="text-xl font-bold">${i18next.t("Upload Sample Transaction Data File")}</div>
			<div class="flex flex-wrap gap-2 items-center">
				${uploadTransactionDataButtonTemplate()}
				<div class=${classNames("flex gap-2 items-center", { hidden: !((_a = props.currentConfiguration) == null ? void 0 : _a.TemplateFileReference) })}>
					${fileIcon((_c = (_b = props.currentConfiguration) == null ? void 0 : _b.TemplateFileReference) == null ? void 0 : _c.FileName)}
					<div
						class="hover:underline cursor-pointer truncate"
						@click=${() => __async(this, null, function* () {
      const downloadFile = yield props.getTemplateFile(
        "AllInOne",
        props.loader,
        props.selectedCountryTab,
        props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne"
      );
      simulateDownload(downloadFile);
    })}
					>
						${(_e = (_d = props.currentConfiguration) == null ? void 0 : _d.TemplateFileReference) == null ? void 0 : _e.FileName}
					</div>
				</div>
			</div>
		</div>
	`;
  };
  const dataMappingTemplate = () => html`
		<div class="flex gap-8 w-full">
			${taxDeskDataTemplate()}
			<div class="flex flex-col gap-2 w-full">${uploadSectionTemplate()} ${uploadedDataTemplate()}</div>
		</div>
	`;
  const mapAllRequiredFieldsErrorTemplate = () => !isEveryRequiredDropFieldSet() && isValidated ? html`<div class="text-red-500 font-semibold">${i18next.t("Please map all required fields at the To be mapped fields list.")}</div> ` : "";
  const template = () => html`
		<div class="m-8 flex flex-col gap-4 w-full">
			<div>${templateTitle(i18next.t("Field Configuration"))}</div>

			${dataMappingTemplate()}
			<div class="flex items-center flex-wrap gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(1) })}
				${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: handleNextButtonClick
  })}
				${mapAllRequiredFieldsErrorTemplate()}
			</div>
		</div>
	`;
  return { template, uploadedFile };
}
