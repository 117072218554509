var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/ComplianceUploads/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { StatusResult } from "./../models/V1/ComplianceUploads/StatusResult";
import { ComplianceUpload } from "./../models/V1/ComplianceUploads/ComplianceUpload";
export class ComplianceUploads {
  /**
   * Creates an instance of ComplianceUploads.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceUploads
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchComplianceUploadsRq_ _searchComplianceUploadsRq
   * @returns Promise<SearchComplianceUploadsRq_>
   * @memberof ComplianceUploads
   */
  searchComplianceUploads(pSearchComplianceUploadsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchComplianceUploadsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedVatMpTransactionsRq_ _removeUploadedVatMpTransactionsRq
   * @returns Promise<RemoveUploadedVatMpTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedVatMpTransactions(pRemoveUploadedVatMpTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/VAT",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedVatMpTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedAmazonTransactionsRq_ _removeUploadedAmazonTransactionsRq
   * @returns Promise<RemoveUploadedAmazonTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedAmazonTransactions(pRemoveUploadedAmazonTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/Amazon",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedAmazonTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateSaftTemplateRq_ _generateSaftTemplateRq
   * @returns Promise<GenerateSaftTemplateRq_>
   * @memberof ComplianceUploads
   */
  generateSaftTemplate(pGenerateSaftTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/GenerateSaftTemplate",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGenerateSaftTemplateRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof ComplianceUploads
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Files/{reference}",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pDownloadFileRq.complianceUploadId, required: true },
      { name: "reference", value: pDownloadFileRq.reference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeVatComplianceUploadRq_ _finalizeVatComplianceUploadRq
   * @returns Promise<FinalizeVatComplianceUploadRq_>
   * @memberof ComplianceUploads
   */
  finalizeVatComplianceUpload(pFinalizeVatComplianceUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeVat",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeVatComplianceUploadRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeSAFTRq_ _finalizeSAFTRq
   * @returns Promise<FinalizeSAFTRq_>
   * @memberof ComplianceUploads
   */
  finalizeSAFT(pFinalizeSAFTRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeSAFT",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "confirmNoSaft", value: pFinalizeSAFTRq.confirmNoSaft }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeSAFTRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeMasterDataRq_ _finalizeMasterDataRq
   * @returns Promise<FinalizeMasterDataRq_>
   * @memberof ComplianceUploads
   */
  finalizeMasterData(pFinalizeMasterDataRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeMasterData",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeMasterDataRq.complianceUploadId, required: true }
    ]);
    client.addBody({ value: pFinalizeMasterDataRq.body, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeIossComplianceUploadRq_ _finalizeIossComplianceUploadRq
   * @returns Promise<FinalizeIossComplianceUploadRq_>
   * @memberof ComplianceUploads
   */
  finalizeIossComplianceUpload(pFinalizeIossComplianceUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeIoss",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeIossComplianceUploadRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CheckBalanceRq_ _checkBalanceRq
   * @returns Promise<CheckBalanceRq_>
   * @memberof ComplianceUploads
   */
  checkBalance(pCheckBalanceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/CheckBalance",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pCheckBalanceRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetComplianceRq_ _getComplianceRq
   * @returns Promise<GetComplianceRq_>
   * @memberof ComplianceUploads
   */
  getCompliance(pGetComplianceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGetComplianceRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ComplianceUpload },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
