var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/Company/OssRegistration/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PossibleStatusChange } from "./../models/V1/Company/OssRegistration/PossibleStatusChange";
export class OssRegistration {
  /**
   * Creates an instance of OssRegistration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof OssRegistration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchOssRegistrationsRq_ _searchOssRegistrationsRq
   * @returns Promise<SearchOssRegistrationsRq_>
   * @memberof OssRegistration
   */
  searchOssRegistrations(pSearchOssRegistrationsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Registrations",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchOssRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportOssRegistrationsRq_ _exportOssRegistrationsRq
   * @returns Promise<ExportOssRegistrationsRq_>
   * @memberof OssRegistration
   */
  exportOssRegistrations(pExportOssRegistrationsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/export",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv, application/json",
      options
    );
    client.addBody({ value: pExportOssRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAdministratorRq_ _updateAdministratorRq
   * @returns Promise<UpdateAdministratorRq_>
   * @memberof OssRegistration
   */
  updateAdministrator(pUpdateAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/companies/{companyId}/registration/administrator",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAdministratorRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateAdministratorRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteAdministratorRq_ _deleteAdministratorRq
   * @returns Promise<DeleteAdministratorRq_>
   * @memberof OssRegistration
   */
  deleteAdministrator(pDeleteAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/companies/{companyId}/registration/administrator",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDeleteAdministratorRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableStatusChangesRq_ _getAvailableStatusChangesRq
   * @returns Promise<GetAvailableStatusChangesRq_>
   * @memberof OssRegistration
   */
  getAvailableStatusChanges(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/Registrations/Statuses",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PossibleStatusChange, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof OssRegistration
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/{companyId}/Registrations/{registrationId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeStatusRq.companyId, required: true },
      { name: "registrationId", value: pChangeStatusRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadDocumentRq_ _uploadDocumentRq
   * @returns Promise<UploadDocumentRq_>
   * @memberof OssRegistration
   */
  uploadDocument(pUploadDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/{companyId}/Registrations/{registrationId}/document",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadDocumentRq.companyId, required: true },
      { name: "registrationId", value: pUploadDocumentRq.registrationId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadDocumentRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStartDateRq_ _changeStartDateRq
   * @returns Promise<ChangeStartDateRq_>
   * @memberof OssRegistration
   */
  changeStartDate(pChangeStartDateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/companies/{companyId}/Registrations/{registrationId}/startDate",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeStartDateRq.companyId, required: true },
      { name: "registrationId", value: pChangeStartDateRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangeStartDateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadCertificateRq_ _downloadCertificateRq
   * @returns Promise<DownloadCertificateRq_>
   * @memberof OssRegistration
   */
  downloadCertificate(pDownloadCertificateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/{companyId}/Registrations/{registrationId}/Certificate",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadCertificateRq.companyId, required: true },
      { name: "registrationId", value: pDownloadCertificateRq.registrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDeregistrationDocumentRq_ _downloadDeregistrationDocumentRq
   * @returns Promise<DownloadDeregistrationDocumentRq_>
   * @memberof OssRegistration
   */
  downloadDeregistrationDocument(pDownloadDeregistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/{companyId}/Registrations/{registrationId}/deregistrationdocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadDeregistrationDocumentRq.companyId, required: true },
      { name: "registrationId", value: pDownloadDeregistrationDocumentRq.registrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangePaymentReferenceRq_ _changePaymentReferenceRq
   * @returns Promise<ChangePaymentReferenceRq_>
   * @memberof OssRegistration
   */
  changePaymentReference(pChangePaymentReferenceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/OssRegistration/Companies/{companyId}/Registrations/{registrationId}/PaymentReference",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangePaymentReferenceRq.companyId, required: true },
      { name: "registrationId", value: pChangePaymentReferenceRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangePaymentReferenceRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
