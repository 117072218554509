var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FieldDefaultValueFactory } from "./FieldDefaultValueFactory";
import { FieldTransformationFactory } from "./FieldTransformationFactory";
export class FieldMapping {
  constructor(_data) {
    /**
     */
    __publicField(this, "DefaultValue");
    /**
     */
    __publicField(this, "FallbackSources");
    /**
     */
    __publicField(this, "Source");
    /**
     */
    __publicField(this, "Transformation");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FieldMapping
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.FieldMapping");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      const $typeDefaultValue = _data["DefaultValue"] && _data["DefaultValue"].$type;
      if ($typeDefaultValue) {
        const defaultValue_ = FieldDefaultValueFactory.create($typeDefaultValue);
        this.DefaultValue = defaultValue_.init(_data["DefaultValue"]);
      } else {
        this.DefaultValue = _data["DefaultValue"];
      }
      if (_data["FallbackSources"] && _data["FallbackSources"].constructor === Array) {
        this.FallbackSources = [];
        for (let item of _data["FallbackSources"]) {
          this.FallbackSources.push(item);
        }
      }
      this.Source = _data["Source"];
      const $typeTransformation = _data["Transformation"] && _data["Transformation"].$type;
      if ($typeTransformation) {
        const transformation_ = FieldTransformationFactory.create($typeTransformation);
        this.Transformation = transformation_.init(_data["Transformation"]);
      } else {
        this.Transformation = _data["Transformation"];
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["DefaultValue"] = this.DefaultValue ? this.DefaultValue.toJSON() : void 0;
    if (this.FallbackSources && this.FallbackSources.constructor === Array) {
      _data["FallbackSources"] = [];
      for (let item of this.FallbackSources) {
        _data["FallbackSources"].push(item);
      }
    }
    _data["Source"] = this.Source;
    _data["Transformation"] = this.Transformation ? this.Transformation.toJSON() : void 0;
    return _data;
  }
}
