var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { SearchResponse } from "./../models/V1/Cesop/Registrations/Search/SearchResponse";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Registrations {
  /**
   * Creates an instance of Registrations.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Registrations
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Registrations
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Registrations/Search",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResponse },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof Registrations
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Registrations/{registrationId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "registrationId", value: pChangeStatusRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateRegistrationNumberRq_ _updateRegistrationNumberRq
   * @returns Promise<UpdateRegistrationNumberRq_>
   * @memberof Registrations
   */
  updateRegistrationNumber(pUpdateRegistrationNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Registrations/{registrationId}/RegistrationNumber",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "registrationId", value: pUpdateRegistrationNumberRq.registrationId, required: true }
    ]);
    client.addBody({ value: pUpdateRegistrationNumberRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
