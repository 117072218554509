import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { FormatTypeMapping } from "../custom-data-template/CustomDataTemplateHelper";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const useCustomSourceTemplateUpload = (props) => {
  var _a;
  const [currentConfiguration, setCurrentConfiguration] = useState();
  useEffect(() => {
    var _a2, _b, _c;
    if (props.generationConfiguration) {
      const config = (_c = (_b = (_a2 = props.generationConfiguration.CountryConfigurations) == null ? void 0 : _a2.find((c) => {
        var _a3;
        return c.CountryCode === ((_a3 = props.currentComplianceUpload) == null ? void 0 : _a3.CountryCode) && !c.IsDeleted;
      })) == null ? void 0 : _b.Configuration) != null ? _c : props.generationConfiguration.DefaultConfiguration;
      setCurrentConfiguration(config);
    }
  }, [props.generationConfiguration, (_a = props.currentComplianceUpload) == null ? void 0 : _a.CountryCode]);
  const htmlFileUpload = (mode, isErrorFileSameAsValidFile, isFileSuccessFullyUploaded, title, uploadType, file) => html`
		<div class="flex flex-col w-full max-w-[600px]">
			<div class="text-xl font-bold truncate mb-2">${title}</div>
			<dc-dropzone-select
				class=${classNames("h-40 rounded-xl bg-gray-100 block", {
    "hover:border-2 hover:border-dashed hover:border-gray-500": !props.hasAnyOngoingDataProcessingJob() && mode !== "Readonly"
  })}
				.uploadButtonLabel=${i18next.t("Select File")}
				.label=${i18next.t("Drop file here to upload")}
				.dragOverText=${i18next.t("Drop file here to upload")}
				.onFileUpload=${(files) => props.uploadFileToBlob(files[0], uploadType)}
				.readonly=${props.hasAnyOngoingDataProcessingJob()}
				.accept=${FormatTypeMapping.get(currentConfiguration == null ? void 0 : currentConfiguration.AvailableTemplates[0])}
			></dc-dropzone-select>
			${props.htmlFileUploadStatus(uploadType, isErrorFileSameAsValidFile, file, isFileSuccessFullyUploaded)}
			${props.isDataProcessingJobOngoingForUploadType(uploadType) ? html`<dc-loader2 .isLoading=${true}></dc-loader2> ` : ""}
		</div>
	`;
  const htmlOneFileUpload = (mode) => {
    var _a2, _b, _c, _d, _e, _f, _g;
    const currentCustomOneFileUploadJob = (_a2 = props.currentUploadJobs) == null ? void 0 : _a2.find((job) => job.type === "CustomOneFile");
    const isCustomOneErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.CustomOneFile) == null ? void 0 : _c.Reference) === ((_d = currentCustomOneFileUploadJob == null ? void 0 : currentCustomOneFileUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isCustomOneFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.CustomOneFile) == null ? void 0 : _f.Reference) && !isCustomOneErrorFileSameAsValidFile;
    return htmlFileUpload(
      mode,
      isCustomOneErrorFileSameAsValidFile,
      isCustomOneFileSuccessFullyUploaded,
      i18next.t("Upload Transactions"),
      "CustomOneFile",
      (_g = props.currentComplianceUpload) == null ? void 0 : _g.CustomOneFile
    );
  };
  const htmlCustomSaleFileUpload = (mode) => {
    var _a2, _b, _c, _d, _e, _f, _g;
    const currentCustomSaleFileUploadJob = (_a2 = props.currentUploadJobs) == null ? void 0 : _a2.find((job) => job.type === "CustomSaleFile");
    const isCustomSaleErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.CustomSaleFile) == null ? void 0 : _c.Reference) === ((_d = currentCustomSaleFileUploadJob == null ? void 0 : currentCustomSaleFileUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isCustomSaleFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.CustomSaleFile) == null ? void 0 : _f.Reference) && !isCustomSaleErrorFileSameAsValidFile;
    return htmlFileUpload(
      mode,
      isCustomSaleErrorFileSameAsValidFile,
      isCustomSaleFileSuccessFullyUploaded,
      i18next.t("Upload Sale Transactions"),
      "CustomSaleFile",
      (_g = props.currentComplianceUpload) == null ? void 0 : _g.CustomSaleFile
    );
  };
  const htmlCustomPurchaseFileUpload = (mode) => {
    var _a2, _b, _c, _d, _e, _f, _g;
    const currentCustomPurchaseFileUploadJob = (_a2 = props.currentUploadJobs) == null ? void 0 : _a2.find((job) => job.type === "CustomPurchaseFile");
    const isCustomPurchaseErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.CustomPurchaseFile) == null ? void 0 : _c.Reference) === ((_d = currentCustomPurchaseFileUploadJob == null ? void 0 : currentCustomPurchaseFileUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isCustomPurchaseFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.CustomPurchaseFile) == null ? void 0 : _f.Reference) && !isCustomPurchaseErrorFileSameAsValidFile;
    return htmlFileUpload(
      mode,
      isCustomPurchaseErrorFileSameAsValidFile,
      isCustomPurchaseFileSuccessFullyUploaded,
      i18next.t("Upload Purchase Transactions"),
      "CustomPurchaseFile",
      (_g = props.currentComplianceUpload) == null ? void 0 : _g.CustomPurchaseFile
    );
  };
  const htmlSeparateFileUpload = (mode) => html`<div class="flex flex-col xl:flex-row gap-4">${htmlCustomSaleFileUpload(mode)}${htmlCustomPurchaseFileUpload(mode)}</div>`;
  const htmlCustomSourceUpload = (mode) => (currentConfiguration == null ? void 0 : currentConfiguration.SalePurchaseSeparately) ? html`${htmlSeparateFileUpload(mode)}` : html`${htmlOneFileUpload(mode)}`;
  return {
    htmlCustomSourceUpload
  };
};
