var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TemplateConfiguration } from "./TemplateConfiguration";
import { CountryConfiguration } from "./CountryConfiguration";
export class GenerationConfiguration {
  constructor(_data) {
    /**
     */
    __publicField(this, "ComplianceUploadGenerationMode");
    /**
     */
    __publicField(this, "CountryConfigurations");
    /**
     */
    __publicField(this, "DefaultConfiguration");
    /**
     */
    __publicField(this, "IsCustomSource");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof GenerationConfiguration
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.GenerationConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ComplianceUploadGenerationMode = GenerationConfigurationComplianceUploadGenerationMode[_data["ComplianceUploadGenerationMode"]];
      if (_data["CountryConfigurations"] && _data["CountryConfigurations"].constructor === Array) {
        this.CountryConfigurations = [];
        for (let item of _data["CountryConfigurations"]) {
          const countryConfigurations_ = new CountryConfiguration();
          this.CountryConfigurations.push(countryConfigurations_.init(item));
        }
      }
      if (_data["DefaultConfiguration"]) {
        const defaultConfiguration_ = new TemplateConfiguration();
        this.DefaultConfiguration = defaultConfiguration_.init(_data["DefaultConfiguration"]);
      }
      this.IsCustomSource = _data["IsCustomSource"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ComplianceUploadGenerationMode"] = GenerationConfigurationComplianceUploadGenerationMode[this.ComplianceUploadGenerationMode];
    if (this.CountryConfigurations && this.CountryConfigurations.constructor === Array) {
      _data["CountryConfigurations"] = [];
      for (let item of this.CountryConfigurations) {
        _data["CountryConfigurations"].push(item.toJSON());
      }
    }
    _data["DefaultConfiguration"] = this.DefaultConfiguration ? this.DefaultConfiguration.toJSON() : void 0;
    _data["IsCustomSource"] = this.IsCustomSource;
    return _data;
  }
}
export var GenerationConfigurationComplianceUploadGenerationMode = /* @__PURE__ */ ((GenerationConfigurationComplianceUploadGenerationMode2) => {
  GenerationConfigurationComplianceUploadGenerationMode2["PerCountry"] = "PerCountry";
  GenerationConfigurationComplianceUploadGenerationMode2["AllCountryInOne"] = "AllCountryInOne";
  return GenerationConfigurationComplianceUploadGenerationMode2;
})(GenerationConfigurationComplianceUploadGenerationMode || {});
