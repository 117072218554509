var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Currency } from "./../models/V1/Common/MasterData/Currency";
import { Country } from "./../models/V1/Common/MasterData/Country";
export class MasterData {
  /**
   * Creates an instance of MasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof MasterData
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ExportSupportedCurrenciesRq_ _exportSupportedCurrenciesRq
   * @returns Promise<ExportSupportedCurrenciesRq_>
   * @memberof MasterData
   */
  exportSupportedCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/ExportSupportedCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetSupportedCurrenciesRq_ _getSupportedCurrenciesRq
   * @returns Promise<GetSupportedCurrenciesRq_>
   * @memberof MasterData
   */
  getSupportedCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/SupportedCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Currency, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCountriesRq_ _getCountriesRq
   * @returns Promise<GetCountriesRq_>
   * @memberof MasterData
   */
  getCountries(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/Countries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetServiceCountriesRq_ _getServiceCountriesRq
   * @returns Promise<GetServiceCountriesRq_>
   * @memberof MasterData
   */
  getServiceCountries(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/ServiceCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEuCountriesRq_ _getEuCountriesRq
   * @returns Promise<GetEuCountriesRq_>
   * @memberof MasterData
   */
  getEuCountries(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/EuCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetBirthCountriesRq_ _getBirthCountriesRq
   * @returns Promise<GetBirthCountriesRq_>
   * @memberof MasterData
   */
  getBirthCountries(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/BirthCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
