var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import {
  mapSearchOrderResult,
  OrderFieldNames,
  mapOrder,
  ALL_ORDER_STATUSES
} from "GlobalShared/components/orders/OrderModels";
import { useOrders } from "GlobalShared/components/orders/orders";
import { DateTimeUtcRange } from "WebServices/APIs/self/models/V1/Common/DateTime";
import { AmountRange } from "WebServices/APIs/self/models/V1/AmountRange";
import { currentLocale, getRouteByKey } from "../../globals";
import { useEnglishWorldCountries, useEUCountries, useServiceCountries, useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { Company as CompanyClient, Companies as CompaniesClient, Orders as OrdersClient, Payments as PaymentsClient } from "WebServices/APIs/self/clients";
import { Money } from "WebServices/APIs/self/models/V1/Common";
import { BankTransferPayment, PayPalPayment } from "WebServices/APIs/self/models/V1/Payments/Pay";
import { mapMoney } from "GlobalShared/mappers/MoneyMapper";
import { SearchRequest } from "WebServices/APIs/self/models/V1/Orders/Search/SearchRequest";
import { Filter } from "WebServices/APIs/self/models/V1/Orders/Search/Filter";
import { Pager } from "WebServices/APIs/self/models/V1/Orders/Search/Pager";
import { Field, FieldOrder } from "WebServices/APIs/self/models/V1/Orders/Search/Field";
import { mapCompanyDetailToCompanyServicesModel } from "GlobalShared/mappers/ServicesMapper";
import { useCommonCalls } from "./admin-common-calls";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
export function AdminOrders() {
  const approveBankTransferPayment = (_model) => __async(this, null, function* () {
  });
  const getFilter = (gridState, showAll) => {
    const clientFilter = new Filter();
    if (!gridState.appliedFilters.some((filter) => filter.field === OrderFieldNames.Status)) {
      clientFilter.Statuses = showAll ? ALL_ORDER_STATUSES : ALL_ORDER_STATUSES.filter((f) => f !== "Cancelled" && f !== "Abandoned");
    }
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === OrderFieldNames.CreatedAt) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.CreatedAt = new DateTimeUtcRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
          });
        }
      } else if (filter.field === OrderFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Statuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.OrderPaymentStatus) {
        if (filter.filterProps.selectedValues) {
          clientFilter.OrderPaymentStatuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.OrderAmount) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.TotalPrice = new AmountRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : 1e6
          });
        }
      } else if (filter.field === OrderFieldNames.ContractId) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ContractId = filter.filterProps.selectedValues;
        }
      }
    });
    return clientFilter;
  };
  const searchOrders = (gridState, showAll, _showProcessingFailure) => __async(this, null, function* () {
    const companiesResult = yield ordersClient.searchOrders({
      body: new SearchRequest({
        Filter: getFilter(gridState, showAll),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new Field({
              Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return mapSearchOrderResult(companiesResult.data);
  });
  const exportOrders = (_gridState, _showAll, _showProcessingFailure) => __async(this, null, function* () {
    return void 0;
  });
  const searchClients = (_fragment) => __async(this, null, function* () {
    return void 0;
  });
  const getCompanyServices = (_companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield companyClient.getCompanyDetail({ locale: currentLocale });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, result.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const reValidateCompanyVatNumber = (_companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield companiesClient.reValidateVatNumber();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getOrder = (orderId) => __async(this, null, function* () {
    const result = yield ordersClient.getOrder({
      orderId
    });
    return mapOrder(result.data);
  });
  const getPaymentInfo = (orderId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const paymentInfoResponse = (yield paymentsClient.getPaymentInfo({ orderId })).data;
        const paymentInfo = {
          availablePaymentTypes: paymentInfoResponse.AvailablePaymentTypes.map((pt) => pt),
          totalPrice: mapMoney(paymentInfoResponse.TotalPrice)
        };
        return paymentInfo;
      }),
      (error) => {
        throw error;
      }
    );
  });
  const payAndConfirm = (payment, orderId) => __async(this, null, function* () {
    const quoted = new Money({
      Currency: payment.quotedPrice.Currency,
      Value: payment.quotedPrice.Value
    });
    const tempPayment = payment.type === "BankTransferPayment" ? new BankTransferPayment({
      Quoted: quoted
    }) : payment.type === "PayPalPayment" ? new PayPalPayment({
      Quoted: quoted,
      Reference: payment.reference
    }) : void 0;
    yield paymentsClient.pay({ body: tempPayment, orderId });
    yield ordersClient.confirmOrder({ orderId });
  });
  const uploadContract = (orderId, contract) => __async(this, null, function* () {
    yield ordersClient.uploadContract({
      orderId,
      file: contract
    });
  });
  const uploadBankTransferPaymentProof = (orderId, paymentId, file) => __async(this, null, function* () {
    yield paymentsClient.uploadBankTransferPaymentProof({
      orderId,
      paymentId,
      file
    });
  });
  const downloadBankTransferPaymentProof = (orderId, paymentId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const resp = yield paymentsClient.downloadBankTransferPaymentProof({
          orderId,
          paymentId
        });
        simulateDownload(resp.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const downloadContract = (orderId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const resp = yield ordersClient.downloadContract({
          orderId
        });
        simulateDownload(resp.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateOrder = (_orderId, _updatedServices) => __async(this, null, function* () {
  });
  const approveDraftOrder = (_orderId) => __async(this, null, function* () {
  });
  const removeVat = (_orderId) => __async(this, null, function* () {
  });
  const rejectDraftOrder = (_orderId) => __async(this, null, function* () {
  });
  const cancelOrder = (orderId) => __async(this, null, function* () {
    yield ordersClient.cancelOrder({
      orderId
    });
  });
  const downloadBillingDocument = (orderId, documentId) => __async(this, null, function* () {
    const result = yield ordersClient.downloadBillingDocument({
      orderId,
      documentId
    });
    simulateDownload(result.data);
  });
  const fixOrder = (_orderId) => __async(this, null, function* () {
  });
  const companyClient = useClient(CompanyClient);
  const companiesClient = useClient(CompaniesClient);
  const ordersClient = useClient(OrdersClient);
  const paymentsClient = useClient(PaymentsClient);
  const serviceCountries = useServiceCountries(currentLocale);
  const euCountries = useEUCountries(currentLocale);
  const worldCountries = useWorldCountries(currentLocale);
  const englishWorldCountries = useEnglishWorldCountries();
  const commonCalls = useCommonCalls();
  const orderHook = useOrders({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    serviceCountries,
    euCountries,
    worldCountries,
    englishWorldCountries,
    getRouteByKey,
    approveBankTransferPayment,
    searchOrders,
    searchClients,
    exportOrders,
    getCompanyServices,
    reValidateCompanyVatNumber,
    downloadContract,
    downloadBankTransferPaymentProof,
    uploadBankTransferPaymentProof,
    getAvailableServices: (_companyId, currencyCode, vatCountrySelection, iossSelection, ossSelection) => commonCalls.getAvailableServices(currencyCode, vatCountrySelection, iossSelection, ossSelection),
    createOrder: commonCalls.createOrder,
    getOrder,
    getPaymentInfo,
    payAndConfirm,
    uploadContract,
    approveDraftOrder,
    removeVat,
    rejectDraftOrder,
    cancelOrder,
    updateOrder,
    downloadBillingDocument,
    fixOrder
  });
  return orderHook.mainTemplate;
}
