var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { CompanyConfiguration } from "./../models/V1/ComplianceUploads/Configuration/CompanyConfiguration";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class ComplianceCompany {
  /**
   * Creates an instance of ComplianceCompany.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceCompany
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetDefaultConfigurationRq_ _getDefaultConfigurationRq
   * @returns Promise<GetDefaultConfigurationRq_>
   * @memberof ComplianceCompany
   */
  getDefaultConfiguration(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Company/DefaultConfiguration",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CompanyConfiguration },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddDefaultConfigurationRq_ _addDefaultConfigurationRq
   * @returns Promise<AddDefaultConfigurationRq_>
   * @memberof ComplianceCompany
   */
  addDefaultConfiguration(pAddDefaultConfigurationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Company/DefaultConfiguration",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pAddDefaultConfigurationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateDefaultConfigurationRq_ _updateDefaultConfigurationRq
   * @returns Promise<UpdateDefaultConfigurationRq_>
   * @memberof ComplianceCompany
   */
  updateDefaultConfiguration(pUpdateDefaultConfigurationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Company/DefaultConfiguration",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pUpdateDefaultConfigurationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
