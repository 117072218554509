var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { ErrorViewModel } from "./../models/ErrorViewModel";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Vies {
  /**
   * Creates an instance of Vies.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Vies
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CheckVatNumberRq_ _checkVatNumberRq
   * @returns Promise<CheckVatNumberRq_>
   * @memberof Vies
   */
  checkVatNumber(pCheckVatNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Signup/CheckVatNumber",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "fullVatNumber", value: pCheckVatNumberRq.fullVatNumber }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 400, clazz: ErrorViewModel },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
